import React from 'react';
import './card.css'
import 'font-awesome/css/font-awesome.min.css';

function Card({ name, length, icon }) {

  return (
    <div className="col-xxl-4 col-md-6">
        <div className="card info-card sales-card">
            <div className="card-body">
                <h5 className="card-title">
                    {name}
                </h5>
                <div className="d-flex align-items-center">
                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className={icon}></i>
                    </div>
                    <div className="ps-3">
                        <h6>{length}</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Card;
