import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

function NavNama() {
  const [user, setUser] = useState('');
  const token = localStorage.getItem('access_token');
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get('https://api.posbindubukitharapan.com/api/get_user_login', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
        });

        if (response.status !== 200) {
          throw new Error('Gagal mengambil data');
        }

        setUser(response.data.data.nama); // Asumsi response.data.data memiliki properti nama
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    fetchUser();
  }, [token]);

  const handleLogout = async () => {
    try {
      await axios.post('https://api.posbindubukitharapan.com/api/logout', {}, {
        headers: {
          'Accept': 'application/json',
          Authorization: `Bearer ${token}`
        },
      });

      // Clear the token from localStorage
      localStorage.removeItem('access_token');
      localStorage.removeItem('id');
      localStorage.removeItem('role');
      window.location.reload();
    } catch (error) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('id');
      localStorage.removeItem('role');
      window.location.reload();
    }
  };

  return (
    <div>
      <div className="dropdown">
        <button className="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          Halo, {user}
        </button>
        <ul className="dropdown-menu">
          <li>
            <Link className="dropdown-item" to="/ubah-password">Ubah Password</Link>
          </li>
          <li>
            <a className="dropdown-item" href="#" onClick={() => setShowModal(true)}>Logout</a>
          </li>
        </ul>
      </div>

      {/* Modal */}
      {showModal && (
        <div className="modal" tabIndex="-1" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content text-center">
              <div className="modal-header">
                <h4 className="modal-title">Konfirmasi Logout</h4>
                <button type="button" className="btn-close" onClick={() => setShowModal(false)}></button>
              </div>
              <div className="modal-body text-center">
                <h5>Apakah anda yakin ingin logout?</h5>
                {error && <p className="text-danger">{error}</p>}
              </div>
              <div className="modal-footer justify-content-center">
                <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Batal</button>
                <button type="button" className="btn btn-danger" onClick={handleLogout}>Logout</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default NavNama;
