import React, { useEffect, useState } from 'react';
import './hasil_pemeriksaan.css';
import PageTitle from '../Main/PageTitle';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

function Hasil_Pemeriksaan() {
  const location = useLocation();
  const navigate = useNavigate();
  const { data } = location.state || {};
  const token = localStorage.getItem('access_token');

  const pemeriksaanData = data ? data[0] : null;
  const [peserta, setPeserta] = useState([]);

  const handleBackToPemeriksaan = () => {
    navigate('/pemeriksaan?successMessage=Pemeriksaan berhasil ditambahkan');
  };

  const handleToPemberianVitamin = () => {
    if (pemeriksaanData) {
      navigate('/pemberian-vitamin', {
        state: { pemeriksaan_id: pemeriksaanData.pemeriksaan_id, peserta_id: pemeriksaanData.peserta_id },
      });
    }
  };

  useEffect(() => {
    const fetchPeserta = async () => {
      try {
        const response = await axios.get(`https://api.posbindubukitharapan.com/api/peserta`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          params: {
            nomor_induk: pemeriksaanData.peserta_id
          }
        });

        if (response.status !== 200) {
          throw new Error('Gagal mengambil data');
        }

        setPeserta(response.data.data);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    if (pemeriksaanData.peserta_id) {
      fetchPeserta();
    }
  }, [pemeriksaanData.peserta_id, token]);

  return (
    <main id='hasil_pemeriksaan' className='hasil_pemeriksaan'>
      <PageTitle page="Hasil Pemeriksaan" />

      {pemeriksaanData ? (
        <form className="row g-3">
          <div className="col-md-6">
            <label htmlFor="nama_peserta" className="form-label">Nama Peserta</label>
            <input
              type="text"
              className="form-control"
              id="nama_peserta"
              value={peserta.nama}
              disabled
            />
          </div>

          <div className="col-md-6">
            <label htmlFor="tekanan_darah" className="form-label">Tekanan Darah</label>
            <input
              type="text"
              className="form-control"
              id="tekanan_darah"
              value={pemeriksaanData.tekanan_darah}
              disabled
            />
          </div>

          <div className="col-md-6">
            <label htmlFor="lingkar_perut" className="form-label">Lingkar Perut</label>
            <input
              type="text"
              className="form-control"
              id="lingkar_perut"
              value={pemeriksaanData.lingkar_perut}
              disabled
            />
          </div>

          <div className="col-md-6">
            <label htmlFor="berat_badan" className="form-label">Berat Badan</label>
            <input
              type="text"
              className="form-control"
              id="berat_badan"
              value={pemeriksaanData.berat_badan}
              disabled
            />
          </div>

          <div className="col-md-6">
            <label htmlFor="tinggi_badan" className="form-label">Tinggi Badan</label>
            <input
              type="text"
              className="form-control"
              id="tinggi_badan"
              value={pemeriksaanData.tinggi_badan}
              disabled
            />
          </div>

          <div className="col-md-6">
            <label htmlFor="gula_darah" className="form-label">Gula Darah</label>
            <input
              type="text"
              className="form-control"
              id="gula_darah"
              value={pemeriksaanData.gula_darah}
              disabled
            />
          </div>

          <div className="col-md-12">
            <label htmlFor="catatan" className="form-label">Catatan</label>
            <textarea
              className="form-control"
              id="catatan"
              rows="3"
              value={pemeriksaanData.catatan}
              disabled
            ></textarea>
          </div>

          <div className="col-12 d-flex justify-content-center">
            <button type="button" className="btn btn-secondary" onClick={handleBackToPemeriksaan}>
              Selesai
            </button>
            <button type="button" className="btn btn-primary" onClick={handleToPemberianVitamin}>
              Pemberian Vitamin
            </button>
          </div>
        </form>
      ) : (
        <p>Tidak ada data.</p>
      )}
    </main>
  );
}

export default Hasil_Pemeriksaan;
