import React from 'react'

function PageTitle({ page }) {
    return (
        <div className="pageTitle">
            <h1>{page}</h1>
        </div>
    )
}

export default PageTitle