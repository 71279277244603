import React, { useState, useEffect } from 'react';
import './edit_vitamin.css';
import PageTitle from '../Main/PageTitle';
import axios from 'axios';
import { Navigate, useLocation } from 'react-router-dom';

function Edit_Vitamin() {

  const token = localStorage.getItem('access_token');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const [isLoading, setIsLoading] = useState(false);

  const [vitaminData, setVitaminData] = useState(null);
  const [errors, setErrors] = useState({});
  const [redirect, setRedirect] = useState(false);

  const [namaVitamin, setNamaVitamin] = useState('');
  const [jumlahVitamin, setJumlahVitamin] = useState('');

  useEffect(() => {
    const fetchVitaminData = async () => {
      if (!id) return;
      try {
        const response = await axios.get(`https://api.posbindubukitharapan.com/api/vitamin/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
        });

        if (response.status !== 200) {
          throw new Error('Gagal mengambil data');
        }

        setVitaminData(response.data.data);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    fetchVitaminData();
  }, [id, token]);

  useEffect(() => {
    if (vitaminData) {
      setNamaVitamin(vitaminData.nama_vitamin || '');
      setJumlahVitamin(vitaminData.jumlah_vitamin || '');
    }
  }, [vitaminData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    setIsLoading(true);

    try {
      const response = await axios.put(`https://api.posbindubukitharapan.com/api/vitamin/${id}`, {
        nama_vitamin: namaVitamin,
        jumlah_vitamin: jumlahVitamin,
      }, {
        headers: {
          'Accept': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        console.log('Berhasil Ubah Data Vitamin:', response.data.message);
        setRedirect(true);
      } else {
        throw new Error('Gagal mengubah data');
      }
    } catch (error) {
      if (error.response) {
        const errorData = error.response.data;
        if (errorData.errors) {
          setErrors(errorData.errors);
        }
      }
    } finally {
      setIsLoading(false);  
    }
  };

  // Menampilkan loading jika data masih dimuat
  if (!vitaminData) {
    return <div>Loading...</div>;
  }

  return (
    <main id='edit_vitamin' className='edit_vitamin'>
      <PageTitle page="Edit Vitamin"/>
      <form className="row g-3" onSubmit={handleSubmit}>
        <div className="col-md-12">
          <label htmlFor="nama_vitamin" className="form-label">Nama Vitamin</label>
          <input 
            type="text" 
            className={`form-control ${errors.nama_vitamin ? 'is-invalid' : ''}`} 
            id="nama_vitamin" 
            value={namaVitamin} 
            onChange={(e) => setNamaVitamin(e.target.value)} 
          />
          {errors.nama_vitamin && <div className="invalid-feedback">{errors.nama_vitamin[0]}</div>}
        </div>
        <div className="col-md-12">
          <label htmlFor="jumlah_vitamin" className="form-label">Jumlah Vitamin</label>
          <input 
            type="text" 
            className={`form-control ${errors.jumlah_vitamin ? 'is-invalid' : ''}`} 
            id="jumlah_vitamin" 
            value={jumlahVitamin} 
            onChange={(e) => setJumlahVitamin(e.target.value)} 
          />
          {errors.jumlah_vitamin && <div className="invalid-feedback">{errors.jumlah_vitamin[0]}</div>}
        </div>
        <button type="submit" className="btn btn-primary" disabled={isLoading}>
            {isLoading ? 'Loading...' : 'Submit'}
        </button>
      </form>
      {redirect && <Navigate to="/vitamin?successMessage=Data berhasil diubah" />}
    </main>
  );
}

export default Edit_Vitamin;
