import React from 'react'
import KesehatanCharts from './KesehatanCharts'

function GrafikKesehatan({ filter }) {
  return (
    <div className="card">
        <div className="card-body">
            <h5 className="card-title">
                Grafik Kesehatan
            </h5>
            <KesehatanCharts filters={filter} />
        </div>
    </div>
  )
}

export default GrafikKesehatan