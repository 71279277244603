import React, { useState } from 'react'
import './tambah_vitamin.css'
import PageTitle from '../Main/PageTitle'
import axios from 'axios';
import { Navigate } from 'react-router-dom';

function Tambah_Vitamin() {

  const [namaVitamin, setNamaVitamin] = useState('');
  const [jumlahVitamin, setJumlahVitamin] = useState('');
  const [errors, setErrors] = useState({});
  const [redirect, setRedirect] = useState(false);
  const token = localStorage.getItem('access_token');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    setIsLoading(true);

    try {
      const response = await axios.post('https://api.posbindubukitharapan.com/api/vitamin', {
        nama_vitamin: namaVitamin,
        jumlah_vitamin: jumlahVitamin,
      }, {
        headers: {
          'Accept': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 201) {
        console.log('Berhasil Tambah Data Pasien:', response.data.message);
        setRedirect(true);
      } else {
        throw new Error('Gagal mengambil data');
      }
    } catch (error) {
      if (error.response) {
        const errorData = error.response.data;
        if (errorData.errors) {
          setErrors(errorData.errors);
        } 
      }
    }  finally {
      setIsLoading(false);  
    }
  };

  return (
    <main id='tambah_vitamin' className='tambah_vitamin'>
      <PageTitle page="Tambah Vitamin"/>
      
      <form className="row g-3" onSubmit={handleSubmit}>
        <div className="col-md-12">
          <label htmlFor="nama_vitamin" className="form-label">Nama Vitamin</label>
          <input 
            type="text" 
            className={`form-control ${errors.nama_vitamin ? 'is-invalid' : ''}`} 
            id="nama_vitamin" 
            value={namaVitamin} 
            onChange={(e) => setNamaVitamin(e.target.value)} 
          />
          {errors.nama_vitamin && <div className="invalid-feedback">{errors.nama_vitamin[0]}</div>}
        </div>
        <div className="col-md-12">
          <label htmlFor="jumlah_vitamin" className="form-label">Jumlah Vitamin</label>
          <input 
            type="text" 
            className={`form-control ${errors.jumlah_vitamin ? 'is-invalid' : ''}`} 
            id="jumlah_vitamin" 
            value={jumlahVitamin} 
            onChange={(e) => setJumlahVitamin(e.target.value)} 
          />
          {errors.jumlah_vitamin && <div className="invalid-feedback">{errors.jumlah_vitamin[0]}</div>}
        </div>
        <button type="submit" className="btn btn-primary" disabled={isLoading}>
            {isLoading ? 'Loading...' : 'Submit'}
        </button>
      </form>
      {redirect && <Navigate to="/vitamin?successMessage=Data berhasil ditambahkan" />}
    </main>
  )
}

export default Tambah_Vitamin