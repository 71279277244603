import React, { useEffect, useState } from 'react';
import './info_pemeriksaan.css';
import PageTitle from '../Main/PageTitle';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

function Info_Pemeriksaan() {
    const location = useLocation();
    const navigate = useNavigate();
    const [pemeriksaan, setPemeriksaan] = useState(null); // Initialize with null
    const token = localStorage.getItem('access_token');
  
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    const handleBackToPemeriksaan = () => {
      navigate('/pemeriksaan');
    };
  
    useEffect(() => {
        const fetchPemeriksaan = async () => {
          try {
            const response = await axios.get(`https://api.posbindubukitharapan.com/api/pemeriksaan/${id}`, {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
              },
            });
    
            if (response.status !== 200) {
              throw new Error('Gagal mengambil data');
            }
    
            setPemeriksaan(response.data.data[0]); 
            console.log('Data :', pemeriksaan);
          } catch (error) {
            console.error('Error fetching data:', error.message);
          }
        };
    
        fetchPemeriksaan();
      }, [id, token]);
  
    return (
      <main id='info_pemeriksaan' className='info_pemeriksaan'>
        <PageTitle page="Info Pemeriksaan" />
  
        {pemeriksaan ? (
          <form className="row g-3">
            <div className="col-md-6">
              <label htmlFor="nama_peserta" className="form-label">Nama Peserta</label>
              <input
                type="text"
                className="form-control"
                id="nama_peserta"
                value={pemeriksaan.nama}
                disabled
              />
            </div>
  
            <div className="col-md-6">
              <label htmlFor="tekanan_darah" className="form-label">Tekanan Darah</label>
              <input
                type="text"
                className="form-control"
                id="tekanan_darah"
                value={pemeriksaan.tekanan_darah}
                disabled
              />
            </div>
  
            <div className="col-md-6">
              <label htmlFor="lingkar_perut" className="form-label">Lingkar Perut</label>
              <input
                type="text"
                className="form-control"
                id="lingkar_perut"
                value={pemeriksaan.lingkar_perut}
                disabled
              />
            </div>
  
            <div className="col-md-6">
              <label htmlFor="berat_badan" className="form-label">Berat Badan</label>
              <input
                type="text"
                className="form-control"
                id="berat_badan"
                value={pemeriksaan.berat_badan}
                disabled
              />
            </div>
  
            <div className="col-md-6">
              <label htmlFor="tinggi_badan" className="form-label">Tinggi Badan</label>
              <input
                type="text"
                className="form-control"
                id="tinggi_badan"
                value={pemeriksaan.tinggi_badan}
                disabled
              />
            </div>
  
            <div className="col-md-6">
              <label htmlFor="gula_darah" className="form-label">Gula Darah</label>
              <input
                type="text"
                className="form-control"
                id="gula_darah"
                value={pemeriksaan.gula_darah}
                disabled
              />
            </div>
  
            <div className="col-md-6">
              <label htmlFor="catatan" className="form-label">Catatan</label>
              <textarea
                className="form-control"
                id="catatan"
                rows="2"
                value={pemeriksaan.catatan}
                disabled
              ></textarea>
            </div>

            <div className="col-md-6">
              <label htmlFor="nama_vitamin" className="form-label">Vitamin</label>
              <input
                type="text"
                className="form-control"
                id="nama_vitamin"
                value={pemeriksaan.nama_vitamin || "Tidak diberikan"}
                disabled
              />
            </div>
  
            <div className="col-12 d-flex justify-content-center">
              <button type="button" className="btn btn-secondary" onClick={handleBackToPemeriksaan}>
                Selesai
              </button>
            </div>
          </form>
        ) : (
          <p>Tidak ada data.</p>
        )}
      </main>
    );
}

export default Info_Pemeriksaan;
