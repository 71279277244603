import React, { useState, useEffect } from 'react'
import './peserta.css'
import PageTitle from '../Main/PageTitle'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';

function Peserta() {
  const [peserta, setPeserta] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [error, setError] = useState(null);
  const token = localStorage.getItem('access_token');
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchPeserta = async (query = '', page = 1) => {
      try {
        const response = await axios.get('https://api.posbindubukitharapan.com/api/pesertas', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }, params: {
            search: query,
            page: page
          },
        });

        if (response.status !== 200) {
          throw new Error('Gagal mengambil data');
        }

        const data = response.data.data;
        setCurrentPage(data.current_page);
        setTotalPages(data.last_page);
        setPeserta(data.data);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    fetchPeserta(searchQuery, currentPage);

  }, [token, searchQuery, currentPage]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const successMessage = searchParams.get('successMessage');
    if (successMessage) {
      toast.success(successMessage);
      searchParams.delete('successMessage');
      window.history.replaceState(null, '', '?' + searchParams.toString());
    }
  }, [location]);

  const handleDeleteClick = (nomorInduk) => {
    setDeleteId(nomorInduk);
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete(`https://api.posbindubukitharapan.com/api/pesertas/delete`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        data: {
          nomor_induk: deleteId
        }
      });

      if (response.status !== 200) {
        throw new Error('Gagal menghapus data');
      }

      setPeserta(peserta.filter(item => item.nomor_induk !== deleteId));
      setShowModal(false);
      toast.success('Data Berhasil Dihapus');
    } catch (error) {
      setError(error.message);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString || dateString === '0000-00-00') return '';
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString.replace(/-/g, '/')).toLocaleDateString('id-ID', options);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleButtonClick = () => {
    navigate('/konfirmasi-guest', { state: { value: 'Peserta' } });
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const renderPagination = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5;

    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= maxPagesToShow - 2) {
        for (let i = 1; i < maxPagesToShow; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push('...');
        pageNumbers.push(totalPages);
      } else if (currentPage > totalPages - (maxPagesToShow - 2)) {
        pageNumbers.push(1);
        pageNumbers.push('...');
        for (let i = totalPages - (maxPagesToShow - 2); i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      } else {
        pageNumbers.push(1);
        pageNumbers.push('...');
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push('...');
        pageNumbers.push(totalPages);
      }
    }

    return pageNumbers.map((page, index) => (
      <li key={index} className={`page-item ${page === currentPage ? 'active' : ''}`}>
        <button
          className="page-link"
          onClick={() => page !== '...' && handlePageChange(page)}
          disabled={page === '...'}
        >
          {page}
        </button>
      </li>
    ));
  };

  return (
    <main id='peserta' className='peserta'>
      <PageTitle page="Peserta"/>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="d-flex">
          <Link to="/tambah-peserta" className="me-2">
            <button type="button" className="btn btn-primary">Tambah</button>
          </Link>
          <button type="button" className="btn btn-warning" onClick={handleButtonClick}>Konfirmasi Peserta</button>
        </div>
        <div className="input-group search-bar">
          <input 
            type="text" 
            className="form-control" 
            placeholder="Cari..." 
            aria-label="Search" 
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <span className="input-group-text"><i className="bi bi-search"></i></span>
        </div>
      </div>

      <div className="table-responsive">
      <table className="table table-striped table-bordered border-dark">
        <thead className='table-primary text-center'>
          <tr>
            <th scope="col">Nomor Induk</th>
            <th scope="col">Nama</th>
            <th scope="col">Tanggal Lahir</th>
            <th scope="col">Jenis Kelamin</th>
            <th scope="col">Agama</th>
            <th scope="col">Alamat</th>
            <th scope="col">Nomor Telepon</th>
            <th scope="col">Pendidikan</th>
            <th scope="col">Pekerjaan</th>
            <th scope="col">Status</th>
            <th scope="col">Aksi</th>
          </tr>
        </thead>
        <tbody className='table-light text-center'>
          {peserta.map((item, index) => (
            <tr key={item.id}>
              <td>{item.nomor_induk}</td>
              <td>{item.nama}</td>
              <td>{formatDate(item.tanggal_lahir)}</td>
              <td>{item.jenis_kelamin}</td>
              <td>{item.agama}</td>
              <td>{item.alamat}</td>
              <td>{item.no_telepon}</td>
              <td>{item.pendidikan}</td>
              <td>{item.pekerjaan}</td>
              <td>{item.status}</td>
              <td>
                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                  <Link to={`/riwayat-peserta?nomor_induk=${item.nomor_induk}`}>
                    <button type="button" className="btn btn-success btn-sm">Riwayat</button>
                  </Link>
                  <Link to={`/edit-peserta?nomor_induk=${item.nomor_induk}`}>
                    <button type="button" className="btn btn-warning btn-sm">Edit</button>
                  </Link>
                  <button 
                    type="button" 
                    className="btn btn-danger btn-sm" 
                    onClick={() => handleDeleteClick(item.nomor_induk)}
                  >
                    Hapus
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>

      <nav aria-label="Page navigation example">
        <ul className="pagination">
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>Previous</button>
          </li>
          {renderPagination()}
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>Next</button>
          </li>
        </ul>
      </nav>

      {showModal && (
        <div className="modal" tabIndex="-1" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content text-center">
              <div className="modal-header">
                <h4 className="modal-title">Konfirmasi Hapus</h4>
                <button type="button" className="btn-close" onClick={() => setShowModal(false)}></button>
              </div>
              <div className="modal-body text-center">
                <h5>Apakah anda yakin ingin menghapus data dengan nomor induk : {deleteId} ?</h5>
                {error && <p className="text-danger">{error}</p>}
              </div>
              <div className="modal-footer justify-content-center">
                <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Batal</button>
                <button type="button" className="btn btn-danger" onClick={handleConfirmDelete}>Hapus</button>
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </main>
  );
}

export default Peserta;
