import React, { useState, useEffect } from 'react';
import './edit_peserta.css';
import axios from 'axios';
import { Navigate, useLocation } from 'react-router-dom';
import PageTitle from '../Main/PageTitle';

function Edit_Peserta() {
    const token = localStorage.getItem('access_token');
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('nomor_induk');
    const [isLoading, setIsLoading] = useState(false);

    const [pesertaData, setPesertaData] = useState(null);
    const [errors, setErrors] = useState({});
    const [redirect, setRedirect] = useState(false);

    const [nomorInduk, setNomorInduk] = useState('');
    const [nama, setNama] = useState('');
    const [tanggalLahir, setTanggalLahir] = useState('');
    const [jenisKelamin, setJenisKelamin] = useState('');
    const [agama, setAgama] = useState('');
    const [alamat, setAlamat] = useState('');
    const [noTelepon, setNoTelepon] = useState('');
    const [pendidikan, setPendidikan] = useState('');
    const [pekerjaan, setPekerjaan] = useState('');
    const [status, setStatus] = useState('');

    useEffect(() => {
        const fetchPesertaData = async () => {
            try {
                const response = await axios.get('https://api.posbindubukitharapan.com/api/peserta', {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    },
                    params: { 
                        nomor_induk: id 
                    },
                });

                if (response.status !== 200) {
                    throw new Error('Gagal mengambil data');
                }

                setPesertaData(response.data.data);
            } catch (error) {
                console.error('Error fetching data:', error.message);
            }
        };

        if (id) {
            fetchPesertaData();
        }
    }, [id, token]);

    useEffect(() => {
        if (pesertaData) {
            setNomorInduk(pesertaData.nomor_induk || '');
            setNama(pesertaData.nama || '');
            setTanggalLahir(pesertaData.tanggal_lahir || '');
            setJenisKelamin(pesertaData.jenis_kelamin || '');
            setAgama(pesertaData.agama || '');
            setAlamat(pesertaData.alamat || '');
            setNoTelepon(pesertaData.no_telepon || '');
            setPendidikan(pesertaData.pendidikan || '');
            setPekerjaan(pesertaData.pekerjaan || '');
            setStatus(pesertaData.status || '');
        }
    }, [pesertaData]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({});
        setIsLoading(true);

        try {
            const response = await axios.put('https://api.posbindubukitharapan.com/api/pesertas/update', {
                nomor_induk: nomorInduk,
                nama: nama, 
                tanggal_lahir: tanggalLahir, 
                jenis_kelamin: jenisKelamin,
                agama: agama,
                alamat: alamat,
                no_telepon: noTelepon,
                pendidikan: pendidikan,
                pekerjaan: pekerjaan,
                status: status,
            }, {
                headers: {
                    'Accept': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                console.log('Berhasil Ubah Data Peserta:', response.data.message);
                setRedirect(true);
            } else {
                throw new Error('Gagal mengubah data');
            }
        } catch (error) {
            setErrors(error.response.data.errors);
        } finally {
          setIsLoading(false);  
        }
    };

    const getTodayDate = () => {
      return new Date().toISOString().split('T')[0];
    };

  return (
    <main id='edit_peserta' className='edit_peserta'>
      <PageTitle page="Edit Peserta" />
      <form className="row g-3" onSubmit={handleSubmit}>
        <div className="col-md-6">
          <label htmlFor="nomor_induk" className="form-label">NIK</label>
          <input 
            type="text" 
            className="form-control" 
            id="nomor_induk" 
            value={nomorInduk} 
            disabled
            onChange={(e) => setNomorInduk(e.target.value)} 
          />
          {errors.nomor_induk && <p className="text-danger">{errors.nomor_induk[0]}</p>}
        </div>
        <div className="col-md-6">
          <label htmlFor="nama" className="form-label">Nama</label>
          <input 
            type="text" 
            className="form-control" 
            id="nama" 
            value={nama} 
            onChange={(e) => setNama(e.target.value)} 
          />
          {errors.nama && <p className="text-danger">{errors.nama[0]}</p>}
        </div>
        <div className="col-md-6">
            <label htmlFor="tanggal_lahir" className="form-label">Tanggal Lahir</label>
            <input 
            type="date" 
            className="form-control" 
            id="tanggal_lahir" 
            value={tanggalLahir} 
            onChange={(e) => setTanggalLahir(e.target.value)}
            max={getTodayDate()} />
            {errors.tanggal_lahir && <p className="text-danger">{errors.tanggal_lahir[0]}</p>}
        </div>
        <div className="col-md-6">
          <label htmlFor="jenis_kelamin" className="form-label">Jenis Kelamin</label>
          <select 
            className="form-select" 
            id="jenis_kelamin" 
            value={jenisKelamin} 
            onChange={(e) => setJenisKelamin(e.target.value)}
          >
            <option value="" hidden>Pilih Jenis Kelamin</option>
            <option value="Laki-laki">Laki-laki</option>
            <option value="Perempuan">Perempuan</option>
          </select>
          {errors.jenis_kelamin && <p className="text-danger">{errors.jenis_kelamin[0]}</p>}
        </div>
        <div className="col-md-6">
          <label htmlFor="agama" className="form-label">Agama</label>
          <select 
            className="form-select" 
            id="agama" 
            value={agama} 
            onChange={(e) => setAgama(e.target.value)}
          >
            <option value="" hidden>Pilih Agama</option>
            <option value="Islam">Islam</option>
            <option value="Kristen">Kristen</option>
            <option value="Khatolik">Khatolik</option>
            <option value="Hindu">Hindu</option>
            <option value="Buddha">Buddha</option>
            <option value="Konghuchu">Konghuchu</option>
          </select>
          {errors.agama && <p className="text-danger">{errors.agama[0]}</p>}
        </div>
        <div className="col-md-6">
          <label htmlFor="alamat" className="form-label">Alamat</label>
          <input 
            type="text" 
            className="form-control" 
            id="alamat" 
            value={alamat} 
            onChange={(e) => setAlamat(e.target.value)} 
          />
          {errors.alamat && <p className="text-danger">{errors.alamat[0]}</p>}
        </div>
        <div className="col-md-6">
          <label htmlFor="no_telepon" className="form-label">Nomor Telepon</label>
          <input 
            type="text" 
            className="form-control" 
            id="no_telepon" 
            value={noTelepon} 
            onChange={(e) => setNoTelepon(e.target.value)} 
          />
          {errors.no_telepon && <p className="text-danger">{errors.no_telepon[0]}</p>}
        </div>
        <div className="col-md-6">
          <label htmlFor="pendidikan" className="form-label">Pendidikan</label>
          <select 
            className="form-select" 
            id="pendidikan" 
            value={pendidikan} 
            onChange={(e) => setPendidikan(e.target.value)}
          >
            <option value="" hidden>Pilih Pendidikan</option>
            <option value="Tidak Sekolah">Tidak Sekolah</option>
            <option value="TK">TK</option>
            <option value="SD">SD</option>
            <option value="SMP/MTS">SMP/MTS</option>
            <option value="SMA/SMK">SMA/SMK</option>
            <option value="S1">S1</option>
            <option value="S2">S2</option>
            <option value="S3">S3</option>
          </select>
          {errors.pendidikan && <p className="text-danger">{errors.pendidikan[0]}</p>}
        </div>
        <div className="col-md-6">
          <label htmlFor="pekerjaan" className="form-label">Pekerjaan</label>
          <input 
            type="text" 
            className="form-control" 
            id="pekerjaan" 
            value={pekerjaan} 
            onChange={(e) => setPekerjaan(e.target.value)} 
          />
          {errors.pekerjaan && <p className="text-danger">{errors.pekerjaan[0]}</p>}
        </div>
        <div className="col-md-6">
          <label htmlFor="status" className="form-label">Status</label>
          <select 
            className="form-select" 
            id="status" 
            value={status} 
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="" hidden>Pilih Status</option>
            <option value="Belum Menikah">Belum Menikah</option>
            <option value="Menikah">Menikah</option>
          </select>
          {errors.status && <p className="text-danger">{errors.status[0]}</p>}
        </div>
        <button type="submit" className="btn btn-primary" disabled={isLoading}>
            {isLoading ? 'Loading...' : 'Submit'}
        </button>
      </form>
      {redirect && <Navigate to="/peserta?successMessage=Data berhasil diubah" />}
    </main>
  )
}

export default Edit_Peserta;
