import React, { useState, useEffect } from 'react';
import './edit_petugas.css';
import PageTitle from '../Main/PageTitle';
import axios from 'axios';
import { Navigate, useLocation } from 'react-router-dom';

function Edit_Petugas() {

    const token = localStorage.getItem('access_token');
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('nomor_induk');
    const [isLoading, setIsLoading] = useState(false);

    const [petugasData, setPetugasData] = useState(null);
    const [errors, setErrors] = useState({});
    const [redirect, setRedirect] = useState(false);

    const [nomorInduk, setNomorInduk] = useState('');
    const [nama, setNama] = useState('');
    const [noTelepon, setNoTelepon] = useState('');

    useEffect(() => {
        const fetchPetugasData = async () => {
            try {
                const response = await axios.get(`https://api.posbindubukitharapan.com/api/petugas`, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    },
                    params: { 
                        nomor_induk: id 
                    },
                });

                if (response.status !== 200) {
                    throw new Error('Gagal mengambil data');
                }

                setPetugasData(response.data.data);
            } catch (error) {
                console.error('Error fetching data:', error.message);
            }
        };

        if (id) {
            fetchPetugasData();
        }
    }, [id, token]);

    useEffect(() => {
        if (petugasData) {
            setNomorInduk(petugasData.nomor_induk || '');
            setNama(petugasData.nama || '');
            setNoTelepon(petugasData.no_telepon || '');
        }
    }, [petugasData]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({});
        setIsLoading(true);

        try {
            const response = await axios.put('https://api.posbindubukitharapan.com/api/petugas/update', {
                nomor_induk: nomorInduk,
                nama: nama,
                no_telepon: noTelepon,
            }, {
                headers: {
                    'Accept': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                console.log('Berhasil Mengubah Data Petugas:', response.data.message);
                setRedirect(true);
            } else {
                throw new Error('Gagal mengubah data');
            }
        } catch (error) {
            setErrors(error.response.data.errors);
        } finally {
            setIsLoading(false);  
        }
    };

    return (
        <main id='edit_petugas' className='edit_petugas'>
            <PageTitle page="Edit Petugas"/>
            <form className="row g-3" onSubmit={handleSubmit}>
                <div className="col-md-6">
                    <label htmlFor="nomor_induk" className="form-label">NIP</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        id="nomor_induk" 
                        value={nomorInduk} 
                        disabled
                        onChange={(e) => setNomorInduk(e.target.value)} 
                    />
                    {errors.nomor_induk && <p className="text-danger">{errors.nomor_induk[0]}</p>}
                </div>
                <div className="col-md-6">
                    <label htmlFor="nama" className="form-label">Nama</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        id="nama" 
                        value={nama} 
                        onChange={(e) => setNama(e.target.value)} 
                    />
                    {errors.nama && <p className="text-danger">{errors.nama[0]}</p>}
                </div>
                <div className="col-md-6">
                    <label htmlFor="no_telepon" className="form-label">Nomor Telepon</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        id="no_telepon" 
                        value={noTelepon} 
                        onChange={(e) => setNoTelepon(e.target.value)} 
                    />
                    {errors.no_telepon && <p className="text-danger">{errors.no_telepon[0]}</p>}
                </div>
                <button type="submit" className="btn btn-primary" disabled={isLoading}>
                    {isLoading ? 'Loading...' : 'Submit'}
                </button>
            </form>
            {redirect && <Navigate to="/petugas?successMessage=Data berhasil diubah" />}
        </main>
    );
}

export default Edit_Petugas;
