import React, { useState, useEffect } from 'react';
import './tambah_pemeriksaan.css';
import PageTitle from '../Main/PageTitle';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Tambah_Pemeriksaan() {
  const [peserta, setPeserta] = useState([]);
  const [fieldErrors, setFieldErrors] = useState({});
  const token = localStorage.getItem('access_token');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [pesertaId, setPesertaId] = useState('');
  const [tekananDarah1, setTekananDarah1] = useState('');
  const [tekananDarah2, setTekananDarah2] = useState('');
  const [lingkarPerut, setLingkarPerut] = useState('');
  const [beratBadan, setBeratBadan] = useState('');
  const [tinggiBadan, setTinggiBadan] = useState('');
  const [gulaDarah, setGulaDarah] = useState('');

  useEffect(() => {
    const fetchPeserta = async () => {
      try {
        const response = await axios.get('https://api.posbindubukitharapan.com/api/peserta_jadwal', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
        });

        if (response.status !== 200) {
          throw new Error('Gagal mengambil data');
        }

        setPeserta(response.data.data);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    fetchPeserta();
  }, [token]);

  const handleKeyDown = (e, target) => {
    if (e.key === 'ArrowRight' && e.target.value.length === e.target.selectionStart) {
      document.getElementById(target).focus();
    } else if (e.key === 'ArrowLeft' && e.target.selectionStart === 0) {
      document.getElementById(target).focus();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFieldErrors({});
    setIsLoading(true);

    const requestData = {
      peserta_id: pesertaId,
      tekanan_darah1: tekananDarah1,
      tekanan_darah2: tekananDarah2,
      lingkar_perut: lingkarPerut,
      berat_badan: beratBadan,
      tinggi_badan: tinggiBadan,
      gula_darah: gulaDarah,
    };

    try {
      const response = await axios.post('https://api.posbindubukitharapan.com/api/pemeriksaan', requestData, {
        headers: {
          'Accept': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 201) {
        console.log('Berhasil Tambah Data Pemeriksaan:', response.data.message);
        navigate('/hasil-pemeriksaan', { state: { data: response.data.data } });
      } else {
        throw new Error('Gagal mengambil data');
      }
    } catch (error) {
      if (error.response) {
        if (error.response.data.errors) {
          setFieldErrors(error.response.data.errors);
        } else {
          toast.error(`${error.response.data.message}`);
        }
      } else if (error.request) {
        toast.error('Tidak ada respon dari server.');
      } else {
        toast.error('Terjadi kesalahan. Silakan coba lagi.');
      }
    } finally {
      setIsLoading(false);  
    }
  };

  return (
    <main id='tambah_pemeriksaan' className='tambah_pemeriksaan'>
      <PageTitle page="Tambah Pemeriksaan"/>
        
      <form className="row g-3" onSubmit={handleSubmit}>
        <div className="col-md-6">
          <label htmlFor="nama_peserta" className="form-label">Nama Peserta</label>
          <select 
            className="form-control" 
            id="nama_peserta" 
            value={pesertaId} 
            onChange={(e) => setPesertaId(e.target.value)}
          >
            <option value="" hidden>Pilih Peserta</option>
            {peserta.map(p => (
              <option key={p.nomor_induk} value={p.nomor_induk}>
                {p.nama}
              </option>
            ))}
          </select>
          {fieldErrors.peserta_id && <p className="text-danger mt-1">{fieldErrors.peserta_id[0]}</p>}
        </div>

        <div className="col-md-6">
          <label htmlFor="tekanan_darah" className="form-label">Tekanan Darah</label>
          <div className="d-flex align-items-center">
            <input 
              type="text" 
              className="form-control me-2" 
              id="tekanan_darah1" 
              placeholder="120" 
              value={tekananDarah1} 
              onChange={(e) => setTekananDarah1(e.target.value)}
              onKeyDown={(e) => handleKeyDown(e, 'tekanan_darah2')}
            />
            <span>/</span>
            <input 
              type="text" 
              className="form-control ms-2" 
              id="tekanan_darah2" 
              placeholder="80" 
              value={tekananDarah2} 
              onChange={(e) => setTekananDarah2(e.target.value)}
              onKeyDown={(e) => handleKeyDown(e, 'tekanan_darah1')}
            />
            <span className="ms-2">mmHg</span>
          </div>
        </div>

        <div className="col-md-6">
          <label htmlFor="lingkar_perut" className="form-label">Lingkar Perut</label>
          <div className="d-flex align-items-center">
            <input 
              type="text" 
              className="form-control" 
              id="lingkar_perut" 
              placeholder="100" 
              value={lingkarPerut} 
              onChange={(e) => setLingkarPerut(e.target.value)} 
              onKeyDown={(e) => handleKeyDown(e, 'berat_badan')}
            />
            <span className="ms-2">cm</span>
          </div>
            {fieldErrors.lingkar_perut && <p className="text-danger mt-1">{fieldErrors.lingkar_perut[0]}</p>}
        </div>

        <div className="col-md-6">
          <label htmlFor="berat_badan" className="form-label">Berat Badan</label>
          <div className="d-flex align-items-center">
            <input 
              type="text" 
              className="form-control" 
              id="berat_badan" 
              placeholder="50"
              value={beratBadan} 
              onChange={(e) => setBeratBadan(e.target.value)} 
              onKeyDown={(e) => handleKeyDown(e, 'lingkar_perut')}
            />
            <span className="ms-2">kg</span>
          </div>
            {fieldErrors.berat_badan && <p className="text-danger mt-1">{fieldErrors.berat_badan[0]}</p>}
        </div>

        <div className="col-md-6">
          <label htmlFor="tinggi_badan" className="form-label">Tinggi Badan</label>
          <div className="d-flex align-items-center">
            <input 
              type="text" 
              className="form-control" 
              id="tinggi_badan" 
              placeholder="160"
              value={tinggiBadan} 
              onChange={(e) => setTinggiBadan(e.target.value)} 
              onKeyDown={(e) => handleKeyDown(e, 'gula_darah')}
            />
            <span className="ms-2">cm</span>
          </div>
            {fieldErrors.tinggi_badan && <p className="text-danger mt-1">{fieldErrors.tinggi_badan[0]}</p>}
        </div>

        <div className="col-md-6">
          <label htmlFor="gula_darah" className="form-label">Gula Darah</label>
          <div className="d-flex align-items-center">
            <input 
              type="text" 
              className="form-control" 
              id="gula_darah" 
              placeholder="90"
              value={gulaDarah} 
              onChange={(e) => setGulaDarah(e.target.value)}
              onKeyDown={(e) => handleKeyDown(e, 'tinggi_badan')} 
            />
            <span className="ms-2">mg/dL</span>
          </div>
            {fieldErrors.gula_darah && <p className="text-danger mt-1">{fieldErrors.gula_darah[0]}</p>}
        </div>
  
        <button type="submit" className="btn btn-primary" disabled={isLoading}>
            {isLoading ? 'Loading...' : 'Submit'}
        </button>
      </form>
      <ToastContainer />
    </main>
  );
}

export default Tambah_Pemeriksaan;
