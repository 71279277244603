import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import PageTitle from '../Main/PageTitle';
import './pemberian_vitamin.css';

function Pemberian_Vitamin() {
  const location = useLocation();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const { pemeriksaan_id, peserta_id } = location.state || {};

  const [vitamin, setVitamin] = useState([]);
  const [peserta, setPeserta] = useState([]);
  const [selectedVitamin, setSelectedVitamin] = useState('');
  const token = localStorage.getItem('access_token');

  useEffect(() => {
    const fetchPeserta = async () => {
      try {
        const response = await axios.get(`https://api.posbindubukitharapan.com/api/peserta`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          params: {
            nomor_induk: peserta_id
          }
        });

        if (response.status !== 200) {
          throw new Error('Gagal mengambil data');
        }

        setPeserta(response.data.data);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    if (peserta_id) {
      fetchPeserta();
    }
  }, [peserta_id, token]);

  useEffect(() => {
    const fetchVitamin = async () => {
      try {
        const response = await axios.get('https://api.posbindubukitharapan.com/api/vitamin', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        });

        if (response.status !== 200) {
          throw new Error('Gagal mengambil data');
        }

        setVitamin(response.data.data.data);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    fetchVitamin();
  }, [token]);

  const handleVitaminChange = (event) => {
    setSelectedVitamin(event.target.value);
  };

  const handleCancel = () => {
    navigate('/pemeriksaan?successMessage=Pemeriksaan berhasil ditambahkan');
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setError(null);

    const requestData = {
      pemeriksaan_id: String(pemeriksaan_id),
      peserta_id: String(peserta_id),
      vitamin_id: String(selectedVitamin)
    };

    try {
      console.log('Data :',requestData);
      const response = await axios.post('https://api.posbindubukitharapan.com/api/pemeriksaan/vitamin', requestData, {
        headers: {
          'Accept': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 201) {
        console.log('Berhasil Pemberian Vitamin:', response.data.message);
        navigate('/pemeriksaan?successMessage=Pemeriksaan dan Pemberian Vitamin berhasil ditambahkan');
      } else {
        throw new Error('Gagal menyimpan data');
      }
    } catch (error) {
      console.error('Error saving data:', error.message);
      setError('Terjadi kesalahan. Silakan coba lagi.');
    }
  };

  return (
    <main id='pemberian_vitamin' className='pemberian_vitamin'>
      <PageTitle page="Pemberian Vitamin" />

      <div className="form-group">
        <label htmlFor="pesertaId" className="form-label">Nama Peserta</label>
        <input
          type="text"
          id="pesertaId"
          className="form-control"
          value={peserta.nama}
          disabled
        />
      </div>

      <div className="form-group">
        <label htmlFor="vitaminSelect" className="form-label">Pilih Vitamin</label>
        <select
          id="vitaminSelect"
          className="form-control"
          value={selectedVitamin}
          onChange={handleVitaminChange}
        >
          <option value="" disabled hidden>Pilih vitamin</option>
          {vitamin.map((vitamin) => (
            <option key={vitamin.id} value={vitamin.id}>
              {vitamin.nama_vitamin}
            </option>
          ))}
        </select>
      </div>

      <div className="buttons d-flex justify-content-center">
        <button className="btn btn-danger" onClick={handleCancel}>Cancel</button>
        <button className="btn btn-primary" onClick={handleSave}>Save</button>
      </div>

      {error && <p className="text-danger">{error}</p>}
    </main>
  );
}

export default Pemberian_Vitamin;
