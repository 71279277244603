import React, { useState, useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import axios from 'axios';
import { ThreeDots } from 'react-loader-spinner'

function PasienPieChart( {filters} ) {
  const chartRef = useRef(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem('access_token');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.posbindubukitharapan.com/api/dashboard', {
              headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}`
              },
              params:  {
                year: filters.year,
                month: filters.month
            }, 
          });

        if (response.status !== 200) {
          throw new Error('Gagal mengambil data');
        }

        const responseData = response.data;
        setData(responseData);
        setLoading(false); // Set loading menjadi false setelah berhasil mendapatkan data

        // Extract and format data for the pie chart
        const pieChartData = responseData.pie_chart;
        const chartData = Object.keys(pieChartData).map(key => ({
          name: key,
          value: pieChartData[key]
        }));

        const chartInstance = echarts.init(chartRef.current);

        const chartOptions = {
          title: {
            text: '',
            left: 'center'
          },
          tooltip: {
            trigger: 'item'
          },
          legend: {
            orient: 'vertical',
            left: 'left'
          },
          series: [
            {
              name: 'Jumlah',
              type: 'pie',
              radius: '50%',
              data: chartData,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        };

        chartInstance.setOption(chartOptions);

        // Resize chart on window resize
        const handleResize = () => {
          chartInstance.resize();
        };
        window.addEventListener('resize', handleResize);

        // Clean up on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
          chartInstance.dispose();
        };
      } catch (error) {
        console.error('Error fetching data:', error.message);
        setLoading(false); // Set loading menjadi false jika terjadi kesalahan dalam pengambilan data
      }
    };

    fetchData();
  }, [filters]);

  return (
    <div>
      {loading ? ( // Tampilkan loading spinner jika masih loading
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '350px' }}>
          <ThreeDots
              visible={true}
              height="80"
              width="80"
              color="#4fa94d"
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
          />
        </div>
      ) : (
        <div ref={chartRef} style={{ width: '100%', height: '350px' }}></div>
      )}
    </div>
  );
}

export default PasienPieChart;
