import React, { useState, useEffect } from 'react'
import './kesehatan_peserta.css';
import PageTitle from '../Main/PageTitle';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Chart from 'react-apexcharts';

function Kesehatan_Peserta() {
    const token = localStorage.getItem('access_token');
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = localStorage.getItem('id');

    const [pesertaData, setPesertaData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPesertaData = async () => {
            try {
                const response = await axios.get(`https://api.posbindubukitharapan.com/api/pesertas/riwayat`, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    },
                    params: { 
                        nomor_induk: id 
                    },
                });

                if (response.status !== 200) {
                    throw new Error('Gagal mengambil data');
                }

                setPesertaData(response.data.data);
            } catch (error) {
                console.error('Error fetching data:', error.message);
                setError(error.message);
            }
        };

        if (id) {
            fetchPesertaData();
        }
    }, [id, token]);

    useEffect(() => {
        if (pesertaData) {
            console.info('Jumlah: ', pesertaData);
            console.info('Nomor Induk: ', id);
        }
    }, [pesertaData, id]);

    const getChartData = () => {
        if (!pesertaData) return {};

        const categories = Object.keys(pesertaData);
        const series = [
            {
                name: 'Tekanan Darah Sistolik',
                data: categories.map(month => pesertaData[month].tekanan_darah_sistolik),
            },
            {
                name: 'Tekanan Darah Diastolik',
                data: categories.map(month => pesertaData[month].tekanan_darah_diastolik),
            },
            {
                name: 'Gula Darah',
                data: categories.map(month => pesertaData[month].gula_darah),
            },
        ];

        return {
            options: {
                xaxis: {
                    categories,
                },
            },
            series,
        };
    };

    return (
        <main id='kesehatan_peserta' className='kesehatan_peserta'>
            <PageTitle page="Kesehatan Peserta"/>
            {error && <p>Error: {error}</p>}
            {pesertaData ? (
                <Chart options={getChartData().options} series={getChartData().series} type="line" />
            ) : (
                <p>Loading...</p>
            )}
        </main>
    );
}

export default Kesehatan_Peserta