import React from 'react'
import KehadiranCharts from './KehadiranCharts'

function GrafikKehadiran( {filter} ) {
  return (
    <div className="card">
        <div className="card-body">
            <h5 className="card-title">
                Grafik Kehadiran
            </h5>
            <KehadiranCharts filters={filter}/>
        </div>
    </div>
  )
}

export default GrafikKehadiran