import React, { useState } from 'react';
import './tambah_petugas.css';
import PageTitle from '../Main/PageTitle';
import axios from 'axios';
import { Navigate } from 'react-router-dom';

function Tambah_Petugas() {
    const [nomorInduk, setNomorInduk] = useState('');
    const [nama, setNama] = useState('');
    const [noTelepon, setNoTelepon] = useState('');
    const [errors, setErrors] = useState({});
    const [redirect, setRedirect] = useState(false);
    const token = localStorage.getItem('access_token');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({});
        setIsLoading(true);
    
        try {
            const response = await axios.post('https://api.posbindubukitharapan.com/api/petugas', {
                nomor_induk: nomorInduk,
                nama: nama,
                no_telepon: noTelepon,
            }, {
                headers: {
                    'Accept': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
    
            if (response.status === 201) {
                console.log('Berhasil Tambah Data Petugas:', response.data.message);
                setRedirect(true);
            } else {
                throw new Error('Gagal menambah data');
            }
        } catch (error) {
            setErrors(error.response.data.errors);
        } finally {
            setIsLoading(false);  
          }
    };

    return (
        <main id='tambah_petugas' className='tambah_petugas'>
            <PageTitle page="Tambah Petugas"/>
            <form className="row g-3" onSubmit={handleSubmit}>
                <div className="col-md-6">
                    <label htmlFor="nomor_induk" className="form-label">NIP</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        id="nomor_induk" 
                        value={nomorInduk} 
                        onChange={(e) => setNomorInduk(e.target.value)} 
                    />
                    {errors.nomor_induk && <p className="text-danger">{errors.nomor_induk[0]}</p>}
                </div>
                <div className="col-md-6">
                    <label htmlFor="nama" className="form-label">Nama</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        id="nama" 
                        value={nama} 
                        onChange={(e) => setNama(e.target.value)} 
                    />
                    {errors.nama && <p className="text-danger">{errors.nama[0]}</p>}
                </div>
                <div className="col-md-6">
                    <label htmlFor="no_telepon" className="form-label">Nomor Telepon</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        id="no_telepon" 
                        value={noTelepon} 
                        onChange={(e) => setNoTelepon(e.target.value)} 
                    />
                    {errors.no_telepon && <p className="text-danger">{errors.no_telepon[0]}</p>}
                </div>
                <button type="submit" className="btn btn-primary" disabled={isLoading}>
                    {isLoading ? 'Loading...' : 'Submit'}
                </button>
            </form>
            {redirect && <Navigate to="/petugas?successMessage=Data berhasil ditambahkan" />}
        </main>
    );
}

export default Tambah_Petugas;
