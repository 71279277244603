import React, { useState, useEffect } from 'react';
import RecentJadwalTable from './RecentJadwalTable';
import axios from 'axios';
import { ThreeDots } from 'react-loader-spinner';

function JadwalMendatang() {
    const [jadwal, setJadwal] = useState([]);
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem('access_token');

    useEffect(() => {
        const fetchJadwal = async () => {
            try {
                const response = await axios.get('https://api.posbindubukitharapan.com/api/jadwals', {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    },
                });

                if (response.status !== 200) {
                    throw new Error('Gagal mengambil data');
                }

                const data = Array.isArray(response.data.data) ? response.data.data : [];
                setJadwal(data);
                setLoading(false); // Set loading to false after data is fetched
            } catch (error) {
                console.error('Error fetching data:', error.message);
                setLoading(false); // Set loading to false if an error occurs
            }
        };

        fetchJadwal();
    }, []);

    return (
        <div className="card recent-sales overflow-auto">
            <div className="card-body">
                <h5 className="card-title text-center">Jadwal Mendatang</h5>
                {loading ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '300px' }}>
                        <ThreeDots color="#4fa94d" height={50} width={50} />
                    </div>
                ) : (
                    <RecentJadwalTable items={jadwal} />
                )}
            </div>
        </div>
    );
}

export default JadwalMendatang;
