import React from 'react'
import './navBar.css'
import NavNama from './NavNama'

function NavBar() {
  return (
    <nav className="header-nav ms-auto">
        <ul className="d-flex align-items-center">
            <NavNama />
        </ul>
    </nav>

  )
}

export default NavBar