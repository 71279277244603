import React from 'react';
import PropTypes from 'prop-types';

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
}

function formatHour(dateString) {
    const date = new Date(dateString);
    const hour = ('0' + date.getHours()).slice(-2);
    const minute = ('0' + date.getMinutes()).slice(-2);
    return `${hour}:${minute} WIB`;
}

function RecentJadwalTable({ items }) {
  if (!Array.isArray(items)) {
    console.error('Expected items to be an array, but received:', items);
    return null;
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <table className="table table-sm text-center">
        <thead>
          <tr>
            <th scope="col">Nama Kegiatan</th>
            <th scope="col">Tanggal Pelaksanaan</th>
            <th scope="col">Jam Pelaksanaan</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <tr key={item.id}>
              <td>{item.nama_kegiatan}</td>
              <td>{formatDate(item.tanggal_pelaksanaan)}</td>
              <td>{formatHour(item.tanggal_pelaksanaan)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

RecentJadwalTable.propTypes = {
  items: PropTypes.array.isRequired,
};

export default RecentJadwalTable;
