import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import axios from 'axios';
import { ThreeDots } from 'react-loader-spinner';

function KesehatanCharts( { filters } ) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem('access_token');

  const [chartData, setChartData] = useState({
    series: [{
      name: 'Grafik Kesehatan',
      data: []
    }],
    options: {
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: [],
      }
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.posbindubukitharapan.com/api/dashboard', {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    },
                    params:  {
                      year: filters.year,
                      month: filters.month
                  }, 
                });

        if (response.status !== 200) {
          throw new Error('Gagal mengambil data');
        }

        const responseData = response.data;
        setData(responseData);
        setLoading(false); // Set loading menjadi false setelah berhasil mendapatkan data

        // Extract and format data for the chart
        const grafikKesehatan = responseData.grafik_kesehatan;
        const categories = Object.keys(grafikKesehatan);
        const dataSeries = Object.values(grafikKesehatan);

        setChartData({
          series: [{
            name: 'Jumlah',
            data: dataSeries
          }],
          options: {
            ...chartData.options,
            xaxis: {
              categories: categories
            }
          }
        });
      } catch (error) {
        console.error('Error fetching data:', error.message);
        setLoading(false); // Set loading menjadi false jika terjadi kesalahan dalam pengambilan data
      }
    };

    fetchData();
  }, [filters]);

  return (
    <div>
        {loading ? ( // Tampilkan loading spinner jika masih loading
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '350px' }}>
            <ThreeDots
              visible={true}
              height="80"
              width="80"
              color="#4fa94d"
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        ) : (
          <Chart
            options={chartData.options}
            series={chartData.series}
            type="bar"
            height={350}
          />
        )}
    </div>
  );
}

export default KesehatanCharts;
