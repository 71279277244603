import React, { useState, useEffect } from 'react';
import './edit_jadwal.css';
import PageTitle from '../Main/PageTitle';
import axios from 'axios';
import { Navigate, useLocation } from 'react-router-dom';
import moment from 'moment';

import 'moment/locale/id';
moment.locale('id');

function Edit_Jadwal() {
    const token = localStorage.getItem('access_token');
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    const [jadwalData, setJadwalData] = useState(null);
    const [fieldErrors, setFieldErrors] = useState({});
    const [redirect, setRedirect] = useState(false);

    const [namaKegiatan, setNamaKegiatan] = useState('');
    const [tanggalPelaksanaan, setTanggalPelaksanaan] = useState('');
    const [jamPelaksanaan, setJamPelaksanaan] = useState('');
    const [tempatPelaksanaan, setTempatPelaksanaan] = useState('');
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        const fetchJadwalData = async () => {
            try {
                const response = await axios.get(`https://api.posbindubukitharapan.com/api/jadwal/${id}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    },
                });

                if (response.status !== 200) {
                    throw new Error('Gagal mengambil data');
                }

                setJadwalData(response.data.data);
            } catch (error) {
                console.error('Error fetching data:', error.message);
            }
        };

        if (id) {
            fetchJadwalData();
        }
    }, [id, token]);

    useEffect(() => {
        if (jadwalData) {
            setNamaKegiatan(jadwalData.nama_kegiatan || '');
            setTempatPelaksanaan(jadwalData.tempat_pelaksanaan || '');

            // Set nilai tanggal pelaksanaan dalam format yang sesuai untuk input tanggal
            if (jadwalData.tanggal_pelaksanaan) {
                const formattedDate = new Date(jadwalData.tanggal_pelaksanaan).toISOString().split('T')[0];
                setTanggalPelaksanaan(formattedDate);
            }

            // Set nilai jam pelaksanaan dalam format yang sesuai untuk input waktu
            if (jadwalData.tanggal_pelaksanaan) {
                const formattedTime = new Date(jadwalData.tanggal_pelaksanaan).toLocaleTimeString('en-GB', {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                });
                setJamPelaksanaan(formattedTime);
            }
        }
    }, [jadwalData]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFieldErrors({});
        setIsLoading(true);

        try {

            const response = await axios.put(`https://api.posbindubukitharapan.com/api/jadwal/${id}`, {
                nama_kegiatan: namaKegiatan,
                tanggal_pelaksanaan: tanggalPelaksanaan,
                jam_pelaksanaan: jamPelaksanaan,
                tempat_pelaksanaan: tempatPelaksanaan,
            }, {
                headers: {
                    'Accept': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                setRedirect(true);
            } else {
                throw new Error('Gagal mengambil data');
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.errors) {
                setFieldErrors(error.response.data.errors);
            } else {
                console.error('An unexpected error occurred:', error.message);
            }
        } finally {
            setIsLoading(false);  
        }
    };

    const getTomorrowDate = () => {
        const today = new Date();
        today.setDate(today.getDate() + 1);
        return today.toISOString().split('T')[0];
    };

    return (
        <main id='edit_jadwal' className='edit_jadwal'>
            <PageTitle page="Edit Jadwal" />

            <form className="row g-3" onSubmit={handleSubmit}>
                <div className="col-md-6">
                    <label htmlFor="nama_kegiatan" className="form-label">Nama Kegiatan</label>
                    <input type="text" className={`form-control ${fieldErrors.nama_kegiatan ? 'is-invalid' : ''}`} id="nama_kegiatan" value={namaKegiatan} onChange={(e) => setNamaKegiatan(e.target.value)} />
                    {fieldErrors.nama_kegiatan && <div className="invalid-feedback">{fieldErrors.nama_kegiatan[0]}</div>}
                </div>
                <div className="col-md-6">
                    <label htmlFor="tempat_pelaksanaan" className="form-label">Tempat Pelaksanaan</label>
                    <input type="text" className={`form-control ${fieldErrors.tempat_pelaksanaan ? 'is-invalid' : ''}`} id="tempat_pelaksanaan" value={tempatPelaksanaan} onChange={(e) => setTempatPelaksanaan(e.target.value)} />
                    {fieldErrors.tempat_pelaksanaan && <div className="invalid-feedback">{fieldErrors.tempat_pelaksanaan[0]}</div>}
                </div>
                <div className="col-md-6">
                    <label htmlFor="tanggal_pelaksanaan" className="form-label">Tanggal Pelaksanaan</label>
                    <input type="date" className={`form-control ${fieldErrors.tanggal_pelaksanaan ? 'is-invalid' : ''}`} id="tanggal_pelaksanaan" value={tanggalPelaksanaan} onChange={(e) => setTanggalPelaksanaan(e.target.value)} min={getTomorrowDate()} />
                    {fieldErrors.tanggal_pelaksanaan && <div className="invalid-feedback">{fieldErrors.tanggal_pelaksanaan[0]}</div>}
                </div>
                <div className="col-md-6">
                    <label className="form-label">Jam Pelaksanaan</label>
                    <input type="time" className={`form-control ${fieldErrors.jam_pelaksanaan ? 'is-invalid' : ''}`} value={jamPelaksanaan} onChange={(e) => setJamPelaksanaan(e.target.value)} />
                    {fieldErrors.jam_pelaksanaan && <div className="invalid-feedback">{fieldErrors.jam_pelaksanaan[0]}</div>}
                </div>
                <button type="submit" className="btn btn-primary" disabled={isLoading}>
                    {isLoading ? 'Loading...' : 'Submit'}
                </button>
            </form>
            {redirect && <Navigate to="/jadwal?successMessage=Data berhasil diubah" />}
        </main>
    );
}

export default Edit_Jadwal;
