import React from 'react';
import { Link } from 'react-router-dom';
import './sideBar.css';

function SideBar() {
  const role = localStorage.getItem('role');

  const renderSidebarItems = () => {
    if (role === 'Admin') {
      return (
        <>
          <li className="nav-item">
            <Link to="/" className="nav-link">
              <i className="bi bi-grid">
                <span> Dashboard</span>
              </i>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/peserta" className="nav-link">
              <i className="bi bi-person">
                <span> Peserta</span>
              </i>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/petugas" className="nav-link">
              <i className="bi bi-person">
                <span> Petugas</span>
              </i>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/pemeriksaan" className="nav-link">
              <i className="bi bi-clipboard">
                <span> Pemeriksaan</span>
              </i>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/jadwal" className="nav-link">
              <i className="bi bi-calendar-event">
                <span> Jadwal</span>
              </i>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/vitamin" className="nav-link">
              <i className="bi bi-capsule-pill">
                <span> Vitamin</span>
              </i>
            </Link>
          </li>
        </>
      );
    } else if (role === 'Petugas') {
      return (
        <>
          <li className="nav-item">
            <Link to="/peserta" className="nav-link">
              <i className="bi bi-person">
                <span> Peserta</span>
              </i>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/pemeriksaan" className="nav-link">
              <i className="bi bi-clipboard2-pulse">
                <span> Pemeriksaan</span>
              </i>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/jadwal" className="nav-link">
              <i className="bi bi-calendar-event">
                <span> Jadwal</span>
              </i>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/vitamin" className="nav-link">
              <i className="bi bi-capsule-pill">
                <span> Vitamin</span>
              </i>
            </Link>
          </li>
        </>
      );
    } else if (role === 'Peserta') {
      return (
        <li className="nav-item">
          <Link to="/kesehatan-peserta" className="nav-link">
            <i className="bi bi-clipboard2-pulse">
              <span> Kesehatan Peserta</span>
            </i>
          </Link>
        </li>
      );
    } else {
      
      return <p>Role tidak valid</p>;
    }
  };

  return (
    <aside id='sidebar' className='sidebar'>
      <ul className="sidebar-nav" id="sidebar-nav">
        {renderSidebarItems()}
      </ul>
    </aside>
  );
}

export default SideBar;
