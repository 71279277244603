// Update_Password Component
import React, { useState, useEffect } from 'react';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard
} from 'mdb-react-ui-kit';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import Posyandu from '../../images/Puskesmas.jpg';

function Update_Password() {
  const location = useLocation();
  const navigate = useNavigate();

  const [nomorInduk, setNomorInduk] = useState('');
  const [passwordBaru, setPasswordBaru] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (location.state && location.state.nomor_induk) {
      setNomorInduk(location.state.nomor_induk);
    } else {
      navigate('/lupa-akun');
    }
  }, [location, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);
    try {
      const response = await axios.put('https://api.posbindubukitharapan.com/api/user/ubah_password', {
        nomor_induk: nomorInduk,
        password_baru: passwordBaru,
        password_baru_confirmation: passwordConfirm,
      }, {
        headers: {
          'Accept': 'application/json',
        }
      });

      if (response.data.status === 'Berhasil') {
        navigate('/');
      } else {
        setError(response.data.message || 'Akun tidak ditemukan.');
      }
    } catch (error) {
      if (error.response) {
        setError('Data Gagal Diubah.');
      } else {
        console.error('Error fetching data:', error.message);
        setError('Terjadi kesalahan. Silakan coba lagi.');
      }
    } finally {
      setIsLoading(false);  
    }
  };

  return (
    <MDBContainer fluid className="d-flex align-items-center justify-content-center bg-primary" style={{ minHeight: '100vh' }}>
        <MDBRow className='d-flex justify-content-center align-items-center w-100'>
            <MDBCol col='12'>
                <MDBCard className='bg-white my-5 mx-auto' style={{ borderRadius: '1rem', maxWidth: '500px' }}>
                    <form className='p-5 w-100 d-flex flex-column' onSubmit={handleSubmit}>
                        <img src={Posyandu} className="rounded mx-auto d-block" alt="Gambar Logo Posyandu" style={{ borderRadius: '1rem', maxWidth: '200px' }}></img>
                        <h2 className="fw-bold mb-2 text-center">Selamat Datang</h2>
                        <p className="text-black-50 mb-3 text-center">Sistem Informasi POSBINDU PTM</p>
                        <div className="form-floating mb-3">
                            <input 
                                type="password" 
                                className="form-control" 
                                id="floatingNewPassword"  
                                placeholder="Password Baru"
                                value={passwordBaru}
                                onChange={(e) => setPasswordBaru(e.target.value)}
                            />
                            <label htmlFor="floatingNewPassword">Password Baru</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input 
                                type="password" 
                                className="form-control" 
                                id="floatingConfirmPassword"  
                                placeholder="Masukan Lagi Password"
                                value={passwordConfirm}
                                onChange={(e) => setPasswordConfirm(e.target.value)}
                            />
                            <label htmlFor="floatingConfirmPassword">Masukan Lagi Password</label>
                        </div>
                        <button type="submit" className="btn btn-primary" disabled={isLoading}>
                            {isLoading ? 'Loading...' : 'Simpan'}
                        </button>
                        {error && <p className="text-danger text-center mt-3">{error}</p>}
                    </form>
                </MDBCard>
            </MDBCol>
        </MDBRow>
    </MDBContainer>
  );
}

export default Update_Password;
