import React from 'react'
import PasienPieChart from './PasienPieChart'

function PieChartReport( {filter} ) {
  return (
    <div className="card">
        <div className="card-body pb-0">
            <h5 className="card-title">
                Laporan Kesehatan
            </h5>
            <PasienPieChart filters={filter}/>
        </div>
    </div>
  )
}

export default PieChartReport