import React, { useState, useEffect } from 'react';
import './vitamin.css';
import PageTitle from '../Main/PageTitle';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';

function Vitamin() {
  const [vitamin, setVitamin] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const token = localStorage.getItem('access_token');
  const location = useLocation();

  useEffect(() => {
    const fetchVitamin = async (query = '', page = 1) => {
      try {
        const response = await axios.get('https://api.posbindubukitharapan.com/api/vitamin', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          params: {
            nama_vitamin: query,
            page: page
          }
        });

        if (response.status !== 200) {
          throw new Error('Gagal mengambil data');
        }

        const data = response.data.data;
        setVitamin(data.data);
        setCurrentPage(data.current_page);
        setTotalPages(data.last_page);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    fetchVitamin(searchQuery, currentPage);
  }, [token, searchQuery, currentPage]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const successMessage = searchParams.get('successMessage');
    if (successMessage) {
      toast.success(successMessage);
      searchParams.delete('successMessage');
      window.history.replaceState(null, '', '?' + searchParams.toString());
    }
  }, [location]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to first page on new search
  };

  const handleDeleteClick = (id) => {
    setDeleteId(id);
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete(`https://api.posbindubukitharapan.com/api/vitamin/${deleteId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });

      if (response.status !== 200) {
        throw new Error('Gagal menghapus data');
      }

      setVitamin(vitamin.filter(item => item.id !== deleteId));
      setShowModal(false);
      toast.success('Data Berhasil Dihapus');
    } catch (error) {
      setError(error.message);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const renderPagination = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5;

    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= maxPagesToShow - 2) {
        for (let i = 1; i < maxPagesToShow; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push('...');
        pageNumbers.push(totalPages);
      } else if (currentPage > totalPages - (maxPagesToShow - 2)) {
        pageNumbers.push(1);
        pageNumbers.push('...');
        for (let i = totalPages - (maxPagesToShow - 2); i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      } else {
        pageNumbers.push(1);
        pageNumbers.push('...');
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push('...');
        pageNumbers.push(totalPages);
      }
    }

    return pageNumbers.map((page, index) => (
      <li key={index} className={`page-item ${page === currentPage ? 'active' : ''}`}>
        <button
          className="page-link"
          onClick={() => page !== '...' && handlePageChange(page)}
          disabled={page === '...'}
        >
          {page}
        </button>
      </li>
    ));
  };

  return (
    <main id='vitamin' className='vitamin'>
      <PageTitle page="Vitamin" />
      
      <div className="d-flex justify-content-between align-items-center mb-3">
        <Link to="/tambah-vitamin">
          <button type="button" className="btn btn-primary">Tambah</button>
        </Link>
        <div className="input-group search-bar">
          <input 
            type="text" 
            className="form-control" 
            placeholder="Cari..." 
            aria-label="Search" 
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <span className="input-group-text"><i className="bi bi-search"></i></span>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table table-striped table-bordered border-dark">
          <thead className='table-primary text-center'>
            <tr>
              <th scope="col">Nama Vitamin</th>
              <th scope="col">Jumlah Vitamin</th>
              <th scope="col">Aksi</th>
            </tr>
          </thead>
          <tbody className='table-light text-center'>
            {vitamin.map((item) => (
              <tr key={item.id}>
                <td>{item.nama_vitamin}</td>
                <td>{item.jumlah_vitamin}</td>
                <td>
                  <div className="d-flex justify-content-center gap-2">
                    <Link to={`/edit-vitamin?id=${item.id}`}>
                      <button type="button" className="btn btn-warning btn-sm">Edit</button>
                    </Link>
                    <button 
                      type="button" 
                      className="btn btn-danger btn-sm" 
                      onClick={() => handleDeleteClick(item.id)}
                    >
                      Hapus
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <nav aria-label="Page navigation example">
        <ul className="pagination">
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>Previous</button>
          </li>
          {renderPagination()}
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>Next</button>
          </li>
        </ul>
      </nav>

      {showModal && (
        <div className="modal" tabIndex="-1" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content text-center">
              <div className="modal-header">
                <h4 className="modal-title">Konfirmasi Hapus</h4>
                <button type="button" className="btn-close" onClick={() => setShowModal(false)}></button>
              </div>
              <div className="modal-body text-center">
                <h5>Apakah anda yakin ingin menghapus data Vitamin?</h5>
                {error && <p className="text-danger">{error}</p>}
              </div>
              <div className="modal-footer justify-content-center">
                <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Batal</button>
                <button type="button" className="btn btn-danger" onClick={handleConfirmDelete}>Hapus</button>
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </main>
  );
}

export default Vitamin;
