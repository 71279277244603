// Lupa_Akun Component
import React, { useState } from 'react';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard
} from 'mdb-react-ui-kit';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Posyandu from '../../images/Puskesmas.jpg';

function Lupa_Akun() {
  const [nomorInduk, setNomorInduk] = useState('');
  const [error, setError] = useState(null);
  const [accountNotFound, setAccountNotFound] = useState(false); 
  const navigate = useNavigate(); 

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setAccountNotFound(false); 
    try {

      const response = await axios.get('https://api.posbindubukitharapan.com/api/user/lupa_akun', {
        headers: {
          'Accept': 'application/json',
        },
        params: {
          nomor_induk: nomorInduk
        },
      });

      if (response.data.status === 'Berhasil') {
        navigate('/update-password', { state: { nomor_induk: nomorInduk, data: response.data.data } });
      } else {
        setAccountNotFound(true);
        setError(response.data.message || 'Akun tidak ditemukan.');
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setAccountNotFound(true);
        setError('Data Tidak Ditemukan.');
      } else {
        console.error('Error fetching data:', error.message);
        setError('Terjadi kesalahan. Silakan coba lagi.');
      }
    }
  };

  return (
    <MDBContainer fluid className="d-flex align-items-center justify-content-center bg-primary" style={{ minHeight: '100vh' }}>
      <MDBRow className='d-flex justify-content-center align-items-center w-100'>
        <MDBCol col='12'>
          <MDBCard className='bg-white my-5 mx-auto' style={{ borderRadius: '1rem', maxWidth: '500px' }}>
            <form className='p-5 w-100 d-flex flex-column' onSubmit={handleSubmit}>
              <img src={Posyandu} className="rounded mx-auto d-block" alt="Gambar Logo Posyandu" style={{ borderRadius: '1rem', maxWidth: '200px' }}></img>
              <h2 className="fw-bold mb-2 text-center">Selamat Datang</h2>
              <p className="text-black-50 mb-3 text-center">Sistem Informasi POSBINDU PTM</p>
              <div className="form-floating mb-3">
                <input 
                  type="text" 
                  className="form-control" 
                  id="floatingInput" 
                  placeholder="1234567" 
                  value={nomorInduk}
                  onChange={(e) => setNomorInduk(e.target.value)}
                />
                <label htmlFor="floatingInput">Nomor Induk</label>
              </div>
              <input className="btn btn-warning" type="submit" value="Cari" />
              {error && <p className="text-danger text-center mt-3">{error}</p>}
              {accountNotFound && (
                <div className="text-center mt-3">
                  <Link to="/pendaftaran-akun" className="btn btn-link">Daftar Akun Baru</Link>
                </div>
              )}
            </form>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

export default Lupa_Akun;
