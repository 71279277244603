import React, { useState } from 'react';
import './tambah_jadwal.css';
import PageTitle from '../Main/PageTitle';
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import moment from 'moment';

import 'moment/locale/id';
moment.locale('id');

function Tambah_Jadwal() {
    const [namaKegiatan, setNamaKegiatan] = useState('');
    const [tanggalPelaksanaan, setTanggalPelaksanaan] = useState('');
    const [jamPelaksanaan, setJamPelaksanaan] = useState('');
    const [tempatPelaksanaan, setTempatPelaksanaan] = useState('');
    const [fieldErrors, setFieldErrors] = useState({});
    const [redirect, setRedirect] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const token = localStorage.getItem('access_token');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFieldErrors({});
        setIsLoading(true);

        try {
            const response = await axios.post('https://api.posbindubukitharapan.com/api/jadwal', {
                nama_kegiatan: namaKegiatan,
                tanggal_pelaksanaan: tanggalPelaksanaan,
                jam_pelaksanaan: jamPelaksanaan,
                tempat_pelaksanaan: tempatPelaksanaan,
            }, {
                headers: {
                    'Accept': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status === 201) {
                console.log('Berhasil Tambah Data Jadwal');
                setRedirect(true);
            } else {
                throw new Error('Gagal mengambil data');
            }
        } catch (error) {
            setFieldErrors(error.response.data.errors);  
        } finally {
            setIsLoading(false);  
        }
    };

    const getTomorrowDate = () => {
        const today = new Date();
        today.setDate(today.getDate() + 1);
        return today.toISOString().split('T')[0];
    };

    return (
        <main id='tambah_jadwal' className='tambah_jadwal'>
            <PageTitle page="Tambah Jadwal" />
            <form className="row g-3" onSubmit={handleSubmit}>
                <div className="col-md-6">
                    <label htmlFor="nama_kegiatan" className="form-label">Nama Kegiatan</label>
                    <input
                        type="text"
                        className={`form-control ${fieldErrors.nama_kegiatan ? 'is-invalid' : ''}`}
                        id="nama_kegiatan"
                        value={namaKegiatan}
                        onChange={(e) => setNamaKegiatan(e.target.value)}
                    />
                    {fieldErrors.nama_kegiatan && <div className="invalid-feedback">{fieldErrors.nama_kegiatan[0]}</div>}
                </div>
                <div className="col-md-6">
                    <label htmlFor="tempat_pelaksanaan" className="form-label">Tempat Pelaksanaan</label>
                    <input
                        type="text"
                        className={`form-control ${fieldErrors.tempat_pelaksanaan ? 'is-invalid' : ''}`}
                        id="tempat_pelaksanaan"
                        value={tempatPelaksanaan}
                        onChange={(e) => setTempatPelaksanaan(e.target.value)}
                    />
                    {fieldErrors.tempat_pelaksanaan && <div className="invalid-feedback">{fieldErrors.tempat_pelaksanaan[0]}</div>}
                </div>
                <div className="col-md-6">
                    <label htmlFor="tanggal_pelaksanaan" className="form-label">Tanggal Pelaksanaan</label>
                    <input
                        type="date"
                        className={`form-control ${fieldErrors.tanggal_pelaksanaan ? 'is-invalid' : ''}`}
                        id="tanggal_pelaksanaan"
                        value={tanggalPelaksanaan}
                        onChange={(e) => setTanggalPelaksanaan(e.target.value)}
                        min={getTomorrowDate()}
                    />
                    {fieldErrors.tanggal_pelaksanaan && <div className="invalid-feedback">{fieldErrors.tanggal_pelaksanaan[0]}</div>}
                </div>
                <div className="col-md-6">
                    <label className="form-label">Jam Pelaksanaan</label>
                    <input
                        type="time"
                        className={`form-control ${fieldErrors.jam_pelaksanaan ? 'is-invalid' : ''}`}
                        value={jamPelaksanaan}
                        onChange={(e) => setJamPelaksanaan(e.target.value)}
                    />
                    {fieldErrors.jam_pelaksanaan && <div className="invalid-feedback">{fieldErrors.jam_pelaksanaan[0]}</div>}
                </div>
                <button type="submit" className="btn btn-primary" disabled={isLoading}>
                    {isLoading ? 'Loading...' : 'Submit'}
                </button>
            </form>
            {redirect && <Navigate to="/jadwal?successMessage=Data berhasil ditambahkan" />}
        </main>
    );
}

export default Tambah_Jadwal;
