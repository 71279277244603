import React, { useState, useEffect } from 'react';
import './pemeriksaan.css';
import PageTitle from '../Main/PageTitle';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Pemeriksaan() {
  const [pemeriksaan, setPemeriksaan] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const token = localStorage.getItem('access_token');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const location = useLocation();

  useEffect(() => {
    const fetchPemeriksaan = async (query = '', page = 1) => {
      try {
        const response = await axios.get('https://api.posbindubukitharapan.com/api/pemeriksaan', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          params: {
            search: query,
            page: page
          }
        });

        if (response.status !== 200) {
          throw new Error('Gagal mengambil data');
        }

        const data = response.data.data;
        setCurrentPage(data.current_page);
        setTotalPages(data.last_page);
        setPemeriksaan(data.data);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    fetchPemeriksaan(searchQuery, currentPage);
  }, [token, searchQuery, currentPage]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const successMessage = searchParams.get('successMessage');
    if (successMessage) {
      toast.success(successMessage);
      searchParams.delete('successMessage');
      window.history.replaceState(null, '', '?' + searchParams.toString());
    }
  }, [location]);

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('id-ID', options);
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const renderPagination = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5;

    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= maxPagesToShow - 2) {
        for (let i = 1; i < maxPagesToShow; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push('...');
        pageNumbers.push(totalPages);
      } else if (currentPage > totalPages - (maxPagesToShow - 2)) {
        pageNumbers.push(1);
        pageNumbers.push('...');
        for (let i = totalPages - (maxPagesToShow - 2); i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      } else {
        pageNumbers.push(1);
        pageNumbers.push('...');
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push('...');
        pageNumbers.push(totalPages);
      }
    }

    return pageNumbers.map((page, index) => (
      <li key={index} className={`page-item ${page === currentPage ? 'active' : ''}`}>
        <button
          className="page-link"
          onClick={() => page !== '...' && handlePageChange(page)}
          disabled={page === '...'}
        >
          {page}
        </button>
      </li>
    ));
  };

  return (
    <main id='pemeriksaan' className='pemeriksaan'>
      <PageTitle page="Pemeriksaan" />

      <div className="d-flex justify-content-between align-items-center mb-3">
        <Link to="/tambah-pemeriksaan">
          <button type="button" className="btn btn-primary">Tambah</button>
        </Link>
        <div className="input-group search-bar">
          <input
            type="text"
            className="form-control"
            placeholder="Cari..."
            aria-label="Search"
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <span className="input-group-text"><i className="bi bi-search"></i></span>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table table-striped table-bordered border-dark">
          <thead className='table-primary text-center'>
            <tr>
              <th scope="col">Tanggal Pemeriksaan</th>
              <th scope="col">Nama Peserta</th>
              <th scope="col">Aksi</th>
            </tr>
          </thead>
          <tbody className='table-light text-center'>
            {pemeriksaan.map((item, index) => (
              <tr key={item.id}>
                <td>{formatDate(item.created_at)}</td>
                <td>{item.nama}</td>
                <td>
                  <div className="d-flex justify-content-center gap-2">
                    <Link to={`/info-pemeriksaan?id=${item.id}`}>
                      <button type="button" className="btn btn-warning btn-sm">Info</button>
                    </Link>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <nav aria-label="Page navigation example">
        <ul className="pagination">
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>Previous</button>
          </li>
          {renderPagination()}
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>Next</button>
          </li>
        </ul>
      </nav>

      <ToastContainer />
    </main>
  );
}

export default Pemeriksaan;
