import React, { useState, useEffect } from 'react'
import './konfirmasi_guest.css'
import PageTitle from '../Main/PageTitle'
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';

function Konfirmasi_Guest() {

    const [guest, setGuest] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [nomorInduk, setNomorInduk] = useState('');
    const [role, setRole] = useState('');
    const [error, setError] = useState(null);
    const token = localStorage.getItem('access_token');
    const [searchQuery, setSearchQuery] = useState('');

    const location = useLocation();
    const value = location.state?.value;

    useEffect(() => {
        const fetchGuest = async (query = '') => {
            try {
                const response = await axios.get('https://api.posbindubukitharapan.com/api/user/guest', {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }, params: {
                    search: query
                },
                });
        
                if (response.status !== 200) {
                throw new Error('Gagal mengambil data');
                }
        
                setGuest(response.data.data);
            } catch (error) {
                console.error('Error fetching data:', error.message);
            }
            };
        
            fetchGuest(searchQuery);
        }, [token,searchQuery]);

        const handleConfirmClick = (nomorInduk) => {
            setNomorInduk(nomorInduk);
            setRole(value);
            setShowModal(true);
        };
        
        const handleConfirmGuest = async () => {
        try {
            const response = await axios.put(`https://api.posbindubukitharapan.com/api/user/konfirmasi_guest`,{
                nomor_induk : nomorInduk,
                role : role
            }, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
            });
    
            if (response.status !== 200) {
            throw new Error('Gagal Konfirmasi Guest');
            }
    
            setGuest(guest.filter(item => item.nomor_induk !== nomorInduk));
            setShowModal(false);
        } catch (error) {
                setError(error.message);
            }
        };

        
        const handleSearchChange = (e) => {
          setSearchQuery(e.target.value);
        };

  return (
    <main id='konfirmasi_guest' className='konfirmasi_guest'>
      <PageTitle page="Konfirmasi Guest"/>
      <div className="d-flex justify-content-end align-items-center mb-3">
        <div className="input-group search-bar">
          <input 
            type="text" 
            className="form-control" 
            placeholder="Cari..." 
            aria-label="Search" 
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <span className="input-group-text"><i className="bi bi-search"></i></span>
        </div>
      </div>

      <div className="table-responsive">
      <table className="table table-striped table-bordered border-dark">
        <thead className='table-primary text-center'>
          <tr>
            <th scope="col">Nomor Induk</th>
            <th scope="col">Nama</th>
            <th scope="col">Nomor Telepon</th>
            <th scope="col">Aksi</th>
          </tr>
        </thead>
        <tbody className='table-light text-center'>
        {guest.map((item, index) => (
        <tr key={item.id}>
            {/* <th scope="row">{index + 1}</th> */}
            <td>{item.nomor_induk}</td>
            <td>{item.nama}</td>
            <td>{item.no_telepon}</td>
            <td>
            <div class="d-flex justify-content-center">
            <button 
                type="button" 
                className="btn btn-danger btn-sm" 
                onClick={() => handleConfirmClick(item.nomor_induk)}
            >
                Konfirmasi
            </button>
            </div>
            </td>
        </tr>
        ))}
    </tbody>
    </table>
    </div>

      {showModal && (
        <div className="modal" tabIndex="-1" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content text-center">
              <div className="modal-header">
                <h4 className="modal-title">Konfirmasi Akun</h4>
                <button type="button" className="btn-close" onClick={() => setShowModal(false)}></button>
              </div>
              <div className="modal-body text-center">
                <h5>Apakah anda yakin ?</h5>
                {error && <p className="text-danger">{error}</p>}
              </div>
              <div className="modal-footer justify-content-center">
                <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Batal</button>
                <button type="button" className="btn btn-primary" onClick={handleConfirmGuest}>Simpan</button>
              </div>
            </div>
          </div>
        </div>
      )}

    </main>
  )
}

export default Konfirmasi_Guest