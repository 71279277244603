import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

// icons
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'remixicon/fonts/remixicon.css';

// bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';


import './App.css';
import Header from './components/Headers/Header';
import SideBar from './components/Sidebar/SideBar';
import Main from './components/Main/Main';
import Peserta from './components/Peserta/Peserta';
import Pemeriksaan from './components/Pemeriksaan/Pemeriksaan';
import Jadwal from './components/Jadwal/Jadwal';
import Vitamin from './components/Vitamin/Vitamin';
import Petugas from './components/Petugas/Petugas';
import Login from './components/Login/Login';
import Tambah_Peserta from './components/Peserta/Tambah_Peserta';
import Edit_Peserta from './components/Peserta/Edit_Peserta';
import Riwayat_Peserta from './components/Peserta/Riwayat_Peserta';
import Ubah_Password from './components/User/Ubah_Password';
import Tambah_Petugas from './components/Petugas/Tambah_Petugas';
import Edit_Petugas from './components/Petugas/Edit_Petugas';
import Tambah_Vitamin from './components/Vitamin/Tambah_Vitamin';
import Edit_Vitamin from './components/Vitamin/Edit_Vitamin';
import Tambah_Jadwal from './components/Jadwal/Tambah_Jadwal';
import Edit_Jadwal from './components/Jadwal/Edit_Jadwal';

import Tambah_Pemeriksaan from './components/Pemeriksaan/Tambah_Pemeriksaan';
import Hasil_Pemeriksaan from './components/Pemeriksaan/Hasil_Pemeriksaan';
import Pemberian_Vitamin from './components/Pemeriksaan/Pemberian_Vitamin';

import Lupa_Akun from './components/User/Lupa_Akun';
import Update_Password from './components/User/Update_Password';
import Pendaftaran_Akun from './components/User/Pendaftaran_Akun';
import Konfirmasi_Guest from './components/User/Konfirmasi_Guest';
import Info_Pemeriksaan from './components/Pemeriksaan/Info_Pemeriksaan';
import Kesehatan_Peserta from './components/Peserta/Kesehatan_Peserta';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('access_token') ? true : false);
  const role = localStorage.getItem('role');

  return (
    <Router>
      {isLoggedIn && (
        <>
          <Header />
          <SideBar />
        </>
      )}

      <Routes>
      <Route
        path="/login"
        element={
          isLoggedIn ? (
            role === "Admin" ? (
              <Navigate to="/dashboard" />
            ) : role === "Petugas" ? (
              <Navigate to="/peserta" />
            ) : (
              <Navigate to="/kesehatan-peserta" />
            )
          ) : (
            <Login onLogin={() => setIsLoggedIn(true)} />
          )
        }
      />


        {/* Halaman-halaman yang hanya dapat diakses setelah login */}
        <Route path="/dashboard" element={isLoggedIn ? <Main /> : <Navigate to="/login" />} />
        <Route path="/peserta" element={isLoggedIn ? <Peserta /> : <Navigate to="/login" />} />
        <Route path="/pemeriksaan" element={isLoggedIn ? <Pemeriksaan /> : <Navigate to="/login" />} />
        <Route path="/jadwal" element={isLoggedIn ? <Jadwal /> : <Navigate to="/login" />} />
        <Route path="/vitamin" element={isLoggedIn ? <Vitamin /> : <Navigate to="/login" />} />
        <Route path="/petugas" element={isLoggedIn ? <Petugas /> : <Navigate to="/login" />} />
        <Route path="/tambah-peserta" element={isLoggedIn ? <Tambah_Peserta /> : <Navigate to="/login" />} />
        <Route path="/edit-peserta" element={isLoggedIn ? <Edit_Peserta /> : <Navigate to="/login" />} />
        <Route path="/tambah-petugas" element={isLoggedIn ? <Tambah_Petugas /> : <Navigate to="/login" />} />
        <Route path="/edit-petugas" element={isLoggedIn ? <Edit_Petugas /> : <Navigate to="/login" />} />
        <Route path="/riwayat-peserta" element={isLoggedIn ? <Riwayat_Peserta /> : <Navigate to="/login" />} />
        <Route path="/ubah-password" element={isLoggedIn ? <Ubah_Password /> : <Navigate to="/login" />} />

        <Route path="/tambah-vitamin" element={isLoggedIn ? <Tambah_Vitamin /> : <Navigate to="/login" />} />
        <Route path="/edit-vitamin" element={isLoggedIn ? <Edit_Vitamin /> : <Navigate to="/login" />} />
        <Route path="/tambah-jadwal" element={isLoggedIn ? <Tambah_Jadwal /> : <Navigate to="/login" />} />
        <Route path="/edit-jadwal" element={isLoggedIn ? <Edit_Jadwal /> : <Navigate to="/login" />} />

        <Route path="/tambah-pemeriksaan" element={isLoggedIn ? <Tambah_Pemeriksaan /> : <Navigate to="/login" />} />
        <Route path="/hasil-pemeriksaan" element={isLoggedIn ? <Hasil_Pemeriksaan /> : <Navigate to="/login" />} />
        <Route path="/info-pemeriksaan" element={isLoggedIn ? <Info_Pemeriksaan /> : <Navigate to="/login" />} />
        <Route path="/pemberian-vitamin" element={isLoggedIn ? <Pemberian_Vitamin /> : <Navigate to="/login" />} />
        <Route path="/konfirmasi-guest" element={isLoggedIn ? <Konfirmasi_Guest /> : <Navigate to="/login" />} />

        <Route path="/kesehatan-peserta" element={isLoggedIn ? <Kesehatan_Peserta /> : <Navigate to="/login" />} />

        <Route path="/lupa-akun" element={<Lupa_Akun />} />
        <Route path="/update-password" element={<Update_Password />} />
        <Route path="/pendaftaran-akun" element={<Pendaftaran_Akun />} />
        {/* Redirect default jika tidak ada path yang cocok */}
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
}

export default App;
