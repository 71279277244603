import React, { useState } from 'react';
import PageTitle from '../Main/PageTitle';
import './tambah_peserta.css';
import axios from 'axios';
import { Navigate } from 'react-router-dom';

function Tambah_Peserta() {
  const [formData, setFormData] = useState({
    nomorInduk: '',
    nama: '',
    tanggalLahir: '',
    jenisKelamin: '',
    agama: '',
    alamat: '',
    noTelepon: '',
    pendidikan: '',
    pekerjaan: '',
    status: '',
  });

  const [errors, setErrors] = useState({});
  const [redirect, setRedirect] = useState(false);
  const token = localStorage.getItem('access_token');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    setIsLoading(true);

    try {
      const response = await axios.post('https://api.posbindubukitharapan.com/api/pesertas', {
        nomor_induk: formData.nomorInduk,
        nama: formData.nama,
        tanggal_lahir: formData.tanggalLahir,
        jenis_kelamin: formData.jenisKelamin,
        agama: formData.agama,
        alamat: formData.alamat,
        no_telepon: formData.noTelepon,
        pendidikan: formData.pendidikan,
        pekerjaan: formData.pekerjaan,
        status: formData.status,
      }, {
        headers: {
          'Accept': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 201) {
        console.log('Berhasil Tambah Data Peserta:', response.data.message);
        setRedirect(true);
      } else {
        throw new Error('Gagal mengambil data');
      }
    } catch (error) {
        setErrors(error.response.data.errors);
    } finally {
      setIsLoading(false);  
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [id]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [id]: '',
    }));
  };

  const getTodayDate = () => {
    return new Date().toISOString().split('T')[0];
  };

  return (
    <main id='tambah_peserta' className='tambah_peserta'>
      <PageTitle page="Tambah Peserta" />
      <form className="row g-3" onSubmit={handleSubmit}>
        <div className="col-md-6">
          <label htmlFor="nomorInduk" className="form-label">NIK</label>
          <input type="text" className="form-control" id="nomorInduk" value={formData.nomorInduk} onChange={handleChange} />
          {errors.nomor_induk && <p className="text-danger">{errors.nomor_induk[0]}</p>}
        </div>
        <div className="col-md-6">
          <label htmlFor="nama" className="form-label">Nama</label>
          <input type="text" className="form-control" id="nama" value={formData.nama} onChange={handleChange} />
          {errors.nama && <p className="text-danger">{errors.nama[0]}</p>}
        </div>
        <div className="col-md-6">
          <label htmlFor="tanggalLahir" className="form-label">Tanggal Lahir</label>
          <input type="date" className="form-control" id="tanggalLahir" value={formData.tanggalLahir} onChange={handleChange} max={getTodayDate()} />
          {errors.tanggal_lahir && <p className="text-danger">{errors.tanggal_lahir[0]}</p>}
        </div>
        <div className="col-md-6">
          <label htmlFor="jenisKelamin" className="form-label">Jenis Kelamin</label>
          <select className="form-select" id="jenisKelamin" value={formData.jenisKelamin} onChange={handleChange}>
            <option value="" hidden>Pilih Jenis Kelamin</option>
            <option value="Laki-laki">Laki-laki</option>
            <option value="Perempuan">Perempuan</option>
          </select>
          {errors.jenis_kelamin && <p className="text-danger">{errors.jenis_kelamin[0]}</p>}
        </div>
        <div className="col-md-6">
          <label htmlFor="agama" className="form-label">Agama</label>
          <select className="form-select" id="agama" value={formData.agama} onChange={handleChange}>
            <option value="" hidden>Pilih Agama</option>
            <option value="Islam">Islam</option>
            <option value="Kristen">Kristen</option>
            <option value="Khatolik">Khatolik</option>
            <option value="Hindu">Hindu</option>
            <option value="Buddha">Buddha</option>
            <option value="Konghuchu">Konghuchu</option>
          </select>
          {errors.agama && <p className="text-danger">{errors.agama[0]}</p>}
        </div>
        <div className="col-md-6">
          <label htmlFor="alamat" className="form-label">Alamat</label>
          <input type="text" className="form-control" id="alamat" value={formData.alamat} onChange={handleChange} />
          {errors.alamat && <p className="text-danger">{errors.alamat[0]}</p>}
        </div>
        <div className="col-md-6">
          <label htmlFor="noTelepon" className="form-label">Nomor Telepon</label>
          <input type="text" className="form-control" id="noTelepon" value={formData.noTelepon} onChange={handleChange} />
          {errors.no_telepon && <p className="text-danger">{errors.no_telepon[0]}</p>}
        </div>
        <div className="col-md-6">
          <label htmlFor="pendidikan" className="form-label">Pendidikan</label>
          <select className="form-select" id="pendidikan" value={formData.pendidikan} onChange={handleChange}>
            <option value="" hidden>Pilih Pendidikan</option>
            <option value="Tidak Sekolah">Tidak Sekolah</option>
            <option value="TK">TK</option>
            <option value="SD">SD</option>
            <option value="SMP/MTS">SMP/MTS</option>
            <option value="SMA/SMK">SMA/SMK</option>
            <option value="S1">S1</option>
            <option value="S2">S2</option>
            <option value="S3">S3</option>
          </select>
          {errors.pendidikan && <p className="text-danger">{errors.pendidikan[0]}</p>}
        </div>
        <div className="col-md-6">
          <label htmlFor="pekerjaan" className="form-label">Pekerjaan</label>
          <input type="text" className="form-control" id="pekerjaan" value={formData.pekerjaan} onChange={handleChange} />
          {errors.pekerjaan && <p className="text-danger">{errors.pekerjaan[0]}</p>}
        </div>
        <div className="col-md-6">
          <label htmlFor="status" className="form-label">Status</label>
          <select className="form-select" id="status" value={formData.status} onChange={handleChange}>
            <option value="" hidden>Pilih Status</option>
            <option value="Belum Menikah">Belum Menikah</option>
            <option value="Menikah">Menikah</option>
          </select>
          {errors.status && <p className="text-danger">{errors.status[0]}</p>}
        </div>
        <button type="submit" className="btn btn-primary" disabled={isLoading}>
            {isLoading ? 'Loading...' : 'Submit'}
        </button>
      </form>
      {redirect && <Navigate to="/peserta?successMessage=Data berhasil ditambahkan" />}
    </main>
  );
}

export default Tambah_Peserta;
