import React, { useState, useEffect } from 'react';
import './jadwal.css';
import PageTitle from '../Main/PageTitle';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';

function Jadwal() {
  const [jadwal, setJadwal] = useState([]);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const token = localStorage.getItem('access_token');
  const location = useLocation();
  const [deleteId, setDeleteId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchJadwal = async (query = '', page = 1) => {
      try {
        const response = await axios.get('https://api.posbindubukitharapan.com/api/jadwal', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          params: {
            nama: query,
            page: page
          }
        });

        if (response.status !== 200) {
          throw new Error('Gagal mengambil data');
        }

        const data = response.data.data;
        setCurrentPage(data.current_page);
        setTotalPages(data.last_page);
        setJadwal(data.data);
      } catch (error) {
        console.error('Error fetching data:', error.message);
        setError('Gagal mengambil data');
      }
    };

    fetchJadwal(searchQuery, currentPage);

  }, [token, searchQuery, currentPage]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const successMessage = searchParams.get('successMessage');
    if (successMessage) {
      toast.success(successMessage);
      searchParams.delete('successMessage');
      window.history.replaceState(null, '', '?' + searchParams.toString());
    }
  }, [location]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('id-ID', options);
  };

  const formatTime = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const handleDeleteClick = (nomorInduk) => {
    setDeleteId(nomorInduk);
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      // Delete the schedule
      const response = await axios.delete(`https://api.posbindubukitharapan.com/api/jadwal/${deleteId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });

      if (response.status !== 200) {
        throw new Error('Gagal menghapus data jadwal');
      }

      setJadwal(jadwal.filter(item => item.id !== deleteId));
      setShowModal(false);
      toast.success('Data Berhasil Dihapus');
    } catch (error) {
      setError(error.message);
    }
  };

  const isPastToday = (date) => {
    const today = new Date();
    const targetDate = new Date(date);
    return targetDate < today;
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const renderPagination = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5;

    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= maxPagesToShow - 2) {
        for (let i = 1; i < maxPagesToShow; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push('...');
        pageNumbers.push(totalPages);
      } else if (currentPage > totalPages - (maxPagesToShow - 2)) {
        pageNumbers.push(1);
        pageNumbers.push('...');
        for (let i = totalPages - (maxPagesToShow - 2); i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      } else {
        pageNumbers.push(1);
        pageNumbers.push('...');
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push('...');
        pageNumbers.push(totalPages);
      }
    }

    return pageNumbers.map((page, index) => (
      <li key={index} className={`page-item ${page === currentPage ? 'active' : ''}`}>
        <button
          className="page-link"
          onClick={() => page !== '...' && handlePageChange(page)}
          disabled={page === '...'}
        >
          {page}
        </button>
      </li>
    ));
  };

  return (
    <main id='jadwal' className='jadwal'>
      <PageTitle page="Jadwal" />
      <div className="d-flex justify-content-between align-items-center mb-3">
        <Link to="/tambah-jadwal">
          <button type="button" className="btn btn-primary">Tambah</button>
        </Link>
        <div className="input-group search-bar">
          <input
            type="text"
            className="form-control"
            placeholder="Cari..."
            aria-label="Search"
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <span className="input-group-text"><i className="bi bi-search"></i></span>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table table-striped table-bordered border-dark">
          <thead className='table-primary text-center'>
            <tr>
              <th scope="col">Nama Kegiatan</th>
              <th scope="col">Tanggal Pelaksanaan</th>
              <th scope="col">Jam Pelaksanaan</th>
              <th scope="col">Tempat Pelaksanaan</th>
              <th scope="col">Aksi</th>
            </tr>
          </thead>
          <tbody className='table-light text-center'>
            {jadwal.map((item) => (
              <tr key={item.id}>
                <td>{item.nama_kegiatan}</td>
                <td>{formatDate(item.tanggal_pelaksanaan)}</td>
                <td>{formatTime(item.tanggal_pelaksanaan)} WIB</td>
                <td>{item.tempat_pelaksanaan}</td>
                <td>
                  <div className="d-flex justify-content-center gap-2">
                    {!isPastToday(item.tanggal_pelaksanaan) ? (
                      <Link to={`/edit-jadwal?id=${item.id}`}>
                        <button type="button" className="btn btn-warning btn-sm">Edit</button>
                      </Link>
                    ) : (
                      <button type="button" className="btn btn-warning btn-sm" disabled>Edit</button>
                    )}
                    <button 
                      type="button" 
                      className="btn btn-danger btn-sm" 
                      onClick={() => handleDeleteClick(item.id)}
                      disabled={isPastToday(item.tanggal_pelaksanaan)}
                    >
                      Hapus
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {error && <p className="text-danger mt-3">{error}</p>}

      <nav aria-label="Page navigation example">
        <ul className="pagination">
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>Previous</button>
          </li>
          {renderPagination()}
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>Next</button>
          </li>
        </ul>
      </nav>

      {showModal && (
        <div className="modal" tabIndex="-1" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content text-center">
              <div className="modal-header">
                <h4 className="modal-title">Konfirmasi Hapus</h4>
                <button type="button" className="btn-close" onClick={() => setShowModal(false)}></button>
              </div>
              <div className="modal-body text-center">
                <h5>Apakah anda yakin ingin menghapus data dengan id : {deleteId} ?</h5>
                {error && <p className="text-danger">{error}</p>}
              </div>
              <div className="modal-footer justify-content-center">
                <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Batal</button>
                <button type="button" className="btn btn-danger" onClick={handleConfirmDelete}>Hapus</button>
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </main>
  );
}

export default Jadwal;
