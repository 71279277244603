import React, { useState, useEffect } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBBtn } from 'mdb-react-ui-kit';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import Posyandu from '../../images/Puskesmas.jpg';

function Pendaftaran_Akun() {
    const [nomorInduk, setNomorInduk] = useState('');
    const [nama, setNama] = useState('');
    const [tanggalLahir, setTanggalLahir] = useState('');
    const [jenisKelamin, setJenisKelamin] = useState('');
    const [agama, setAgama] = useState('');
    const [alamat, setAlamat] = useState('');
    const [noTelepon, setNoTelepon] = useState('');
    const [pendidikan, setPendidikan] = useState('');
    const [pekerjaan, setPekerjaan] = useState('');
    const [status, setStatus] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [errors, setErrors] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [countdown, setCountdown] = useState(10);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let timer;
        if (showModal) {
            timer = setInterval(() => {
                setCountdown((prevCountdown) => {
                    if (prevCountdown === 1) {
                        clearInterval(timer);
                        setShowModal(false);
                        navigate('/login');
                    }
                    return prevCountdown - 1;
                });
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [showModal, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({});
        setIsLoading(true);

        try {
            const response = await axios.post('https://api.posbindubukitharapan.com/api/user/pendaftaran_akun', {
                nomor_induk: nomorInduk,
                nama: nama,
                tanggal_lahir: tanggalLahir,
                jenis_kelamin: jenisKelamin,
                agama: agama,
                alamat: alamat,
                no_telepon: noTelepon,
                pendidikan: pendidikan,
                pekerjaan: pekerjaan,
                status: status,
                password_baru: password,
                password_baru_confirmation: passwordConfirm,
            }, {
                headers: {
                    'Accept': 'application/json',
                }
            });

            if (response.data.status === 'Berhasil') {
                setShowModal(true);
                setCountdown(10); 
            } else {
                    setErrors(response.data.errors);
            }
        } catch (error) {
            setErrors('Terjadi kesalahan. Silakan coba lagi.');
        } finally {
            setIsLoading(false);  
          }
    };

    const getTodayDate = () => {
        return new Date().toISOString().split('T')[0];
      };
      
    return (
        <MDBContainer fluid className="d-flex align-items-center justify-content-center bg-primary" style={{ minHeight: '100vh' }}>
            <MDBRow className="d-flex justify-content-center align-items-center w-100">
                <MDBCol col="12">
                    <MDBCard className="bg-white my-5 mx-auto" style={{ borderRadius: '1rem', maxWidth: '500px' }}>
                        <form className="p-5 w-100 d-flex flex-column" onSubmit={handleSubmit}>
                            <img src={Posyandu} className="rounded mx-auto d-block mb-4" alt="Gambar Logo Posyandu" style={{ borderRadius: '1rem', maxWidth: '200px' }} />
                            <h2 className="fw-bold mb-2 text-center">Selamat Datang</h2>
                            <p className="text-black-50 mb-3 text-center">Sistem Informasi POSBINDU PTM</p>

                            <div className="form-floating mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="nomorInduk"
                                    value={nomorInduk}
                                    onChange={(e) => setNomorInduk(e.target.value)}
                                    placeholder="Nomor Induk"
                                />
                                <label htmlFor="nomorInduk">Nomor Induk</label>
                                {errors.nomor_induk && <p className="text-danger">{errors.nomor_induk[0]}</p>}
                            </div>

                            <div className="form-floating mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="nama"
                                    value={nama}
                                    onChange={(e) => setNama(e.target.value)}
                                    placeholder="Nama"
                                />
                                <label htmlFor="nama">Nama</label>
                                {errors.nama && <p className="text-danger">{errors.nama[0]}</p>}
                            </div>

                            <div className="form-floating mb-3">
                                <input
                                    type="date"
                                    className="form-control"
                                    id="tanggalLahir"
                                    value={tanggalLahir}
                                    onChange={(e) => setTanggalLahir(e.target.value)}
                                    placeholder="Tanggal Lahir"
                                    max={getTodayDate()}
                                />
                                <label htmlFor="tanggalLahir">Tanggal Lahir</label>
                                {errors.tanggal_lahir && <p className="text-danger">{errors.tanggal_lahir[0]}</p>}
                            </div>

                            <div className="form-floating mb-3">
                                <select
                                    className="form-select"
                                    id="jenisKelamin"
                                    value={jenisKelamin}
                                    onChange={(e) => setJenisKelamin(e.target.value)}
                                >
                                    <option value="" hidden>Pilih Jenis Kelamin</option>
                                    <option value="Laki-laki">Laki-laki</option>
                                    <option value="Perempuan">Perempuan</option>
                                </select>
                                <label htmlFor="jenisKelamin">Jenis Kelamin</label>
                                {errors.jenis_kelamin && <p className="text-danger">{errors.jenis_kelamin[0]}</p>}
                            </div>

                            <div className="form-floating mb-3">
                                <select
                                    className="form-select"
                                    id="agama"
                                    value={agama}
                                    onChange={(e) => setAgama(e.target.value)}
                                >
                                    <option value="" hidden>Pilih Agama</option>
                                    <option value="Islam">Islam</option>
                                    <option value="Kristen">Kristen</option>
                                    <option value="Khatolik">Khatolik</option>
                                    <option value="Hindu">Hindu</option>
                                    <option value="Buddha">Buddha</option>
                                    <option value="Konghuchu">Konghuchu</option>
                                </select>
                                <label htmlFor="agama">Agama</label>
                                {errors.agama && <p className="text-danger">{errors.agama[0]}</p>}
                            </div>

                            <div className="form-floating mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="alamat"
                                    value={alamat}
                                    onChange={(e) => setAlamat(e.target.value)}
                                    placeholder="Alamat"
                                />
                                <label htmlFor="alamat">Alamat</label>
                                {errors.alamat && <p className="text-danger">{errors.alamat[0]}</p>}
                            </div>

                            <div className="form-floating mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="noTelepon"
                                    value={noTelepon}
                                    onChange={(e) => setNoTelepon(e.target.value)}
                                    placeholder="No Telepon"
                                />
                                <label htmlFor="noTelepon">Nomor Telepon</label>
                                {errors.no_telepon && <p className="text-danger">{errors.no_telepon[0]}</p>}
                            </div>

                            <div className="form-floating mb-3">
                                <select
                                    className="form-select"
                                    id="pendidikan"
                                    value={pendidikan}
                                    onChange={(e) => setPendidikan(e.target.value)}
                                >
                                    <option value="" hidden>Pilih Pendidikan</option>
                                    <option value="Tidak Sekolah">Tidak Sekolah</option>
                                    <option value="TK">TK</option>
                                    <option value="SD">SD</option>
                                    <option value="SMP/MTS">SMP/MTS</option>
                                    <option value="SMA/SMK">SMA/SMK</option>
                                    <option value="S1">S1</option>
                                    <option value="S2">S2</option>
                                    <option value="S3">S3</option>
                                </select>
                                <label htmlFor="pendidikan">Pendidikan</label>
                                {errors.pendidikan && <p className="text-danger">{errors.pendidikan[0]}</p>}
                            </div>

                            <div className="form-floating mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="pekerjaan"
                                    value={pekerjaan}
                                    onChange={(e) => setPekerjaan(e.target.value)}
                                    placeholder="Pekerjaan"
                                />
                                <label htmlFor="pekerjaan">Pekerjaan</label>
                                {errors.pekerjaan && <p className="text-danger">{errors.pekerjaan[0]}</p>}
                            </div>

                            <div className="form-floating mb-3">
                                <select
                                    className="form-select"
                                    id="status"
                                    value={status}
                                    onChange={(e) => setStatus(e.target.value)}
                                >
                                    <option value="" hidden>Pilih Status</option>
                                    <option value="Belum Menikah">Belum Menikah</option>
                                    <option value="Menikah">Menikah</option>
                                </select>
                                <label htmlFor="status">Status</label>
                                {errors.status && <p className="text-danger">{errors.status[0]}</p>}
                            </div>

                            <div className="form-floating mb-3">
                                <input
                                    type="password"
                                    className="form-control"
                                    id="passwordBaru"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Password"
                                />
                                <label htmlFor="passwordBaru">Password</label>
                                {errors.password && <p className="text-danger">{errors.password[0]}</p>}
                            </div>

                            <div className="form-floating mb-3">
                                <input
                                    type="password"
                                    className="form-control"
                                    id="passwordConfirm"
                                    value={passwordConfirm}
                                    onChange={(e) => setPasswordConfirm(e.target.value)}
                                    placeholder="Konfirmasi Password"
                                />
                                <label htmlFor="passwordConfirm">Masukkan Lagi Password</label>
                                {errors.password_baru_confirmation && <p className="text-danger">{errors.password_baru_confirmation[0]}</p>}
                            </div>

                            {/* <MDBBtn color="primary" type="submit">
                                Simpan
                            </MDBBtn> */}
                            <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                {isLoading ? 'Loading...' : 'Submit'}
                            </button>
                        </form>
                    </MDBCard>
                </MDBCol>
            </MDBRow>

            {showModal && (
                <div className="modal fade show d-block" tabIndex="-1" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content text-center bg-success text-white">
                            <div className="modal-header">
                                <h5 className="modal-title">Pendaftaran Akun Berhasil</h5>
                            </div>
                            <div className="modal-body">
                                <p>Silahkan Tunggu Pesan Dari Admin Ketika Akun Sudah Dikonfirmasi.</p>
                                <p>Anda akan dialihkan ke halaman login dalam {countdown} detik.</p>
                            </div>
                            <div className="modal-footer">
                                <MDBBtn color="primary" onClick={() => navigate('/login')}>Tutup</MDBBtn>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </MDBContainer>
    );
}

export default Pendaftaran_Akun;
