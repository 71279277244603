import React, { useState } from 'react';
import './login.css';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard
} from 'mdb-react-ui-kit';
import axios from 'axios';
import { Navigate, Link } from 'react-router-dom';
import Posyandu from '../../images/Puskesmas.jpg';

function Login({ onLogin }) {
  const [nomorInduk, setNomorInduk] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);
    try {
      console.log('Sending request with:', { nomor_induk: nomorInduk, password: password });

      const response = await axios.post('https://api.posbindubukitharapan.com/api/login', {
        nomor_induk: nomorInduk,
        password: password
      }, {
        headers: {
          'Accept': 'application/json',
        }
      });

      if (response.status !== 200) {
        throw new Error('Gagal mengambil data');
      }

      if (response.data.status === 'Berhasil' && response.data.role !== 'Guest') {
        localStorage.setItem('access_token', response.data.access_token);
        localStorage.setItem('role', response.data.role);
        localStorage.setItem('id', response.data.id);
        onLogin();
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error.message);
      setError('Login gagal. Silakan coba lagi.');
    } finally {
      setIsLoading(false);  
    }
  };

  if (localStorage.getItem('access_token')) {
    return <Navigate to="/" />;
  }

  return (
    <MDBContainer fluid className="d-flex align-items-center justify-content-center bg-primary" style={{ minHeight: '100vh' }}>
      <MDBRow className='d-flex justify-content-center align-items-center w-100'>
        <MDBCol col='12'>
          <MDBCard className='bg-white my-5 mx-auto' style={{ borderRadius: '1rem', maxWidth: '500px' }}>
            <form className='p-5 w-100 d-flex flex-column' onSubmit={handleSubmit}>
              <img src={Posyandu} className="rounded mx-auto d-block" alt="Gambar Logo Posyandu" style={{ borderRadius: '1rem', maxWidth: '200px' }}></img>
              <h2 className="fw-bold mb-2 text-center">Selamat Datang</h2>
              <p className="text-black-50 mb-3 text-center">Sistem Informasi POSBINDU PTM</p>
              <div className="form-floating mb-3">
                <input 
                  type="text" 
                  className="form-control" 
                  id="floatingInput" 
                  placeholder="1234567" 
                  value={nomorInduk}
                  onChange={(e) => setNomorInduk(e.target.value)}
                />
                <label htmlFor="floatingInput">Nomor Induk</label>
              </div>
              <div className="form-floating mb-5 position-relative">
                <input 
                  type="password" 
                  className="form-control" 
                  id="floatingPassword" 
                  placeholder="Password" 
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <label htmlFor="floatingPassword">Password</label>
                <div className="position-absolute lupa-akun">
                  <Link to="/lupa-akun">Lupa Akun ?</Link>
                </div>
              </div>
              <button type="submit" className="btn btn-primary" disabled={isLoading}>
                  {isLoading ? 'Loading...' : 'Login'}
              </button>
              {error && <p className="text-danger text-center mt-3">{error}</p>}
              <Link to="/pendaftaran-akun" className="text-center mt-3">Daftar Akun Baru</Link>
            </form>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

export default Login;
