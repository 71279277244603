import React, { useState, useEffect } from 'react';
import './ubah_password.css';
import PageTitle from '../Main/PageTitle';
import axios from 'axios';
import { Navigate } from 'react-router-dom';

function Ubah_Password() {
  const [user, setUser] = useState('');
  const token = localStorage.getItem('access_token');
  const [error, setError] = useState('');
  const [passwordLama, setPasswordLama] = useState('');
  const [passwordBaru, setPasswordBaru] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get('https://api.posbindubukitharapan.com/api/get_user_login', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
        });

        if (response.status !== 200) {
          throw new Error('Gagal mengambil data');
        }

        setUser(response.data.data.no_induk);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    fetchUser();
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);

    if (passwordBaru !== passwordConfirm) {
      setError('Password baru dan konfirmasi password tidak cocok');
      return;
    }

    try {
      const response = await axios.put('https://api.posbindubukitharapan.com/api/user/password', {
        nomor_induk: user,
        password_lama: passwordLama,
        password_baru: passwordBaru,
        password_baru_confirmation: passwordConfirm,
      }, {
        headers: {
          'Accept': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        console.log('Berhasil Update Password :', response.data.message);
        setRedirect(true);
      } else {
        throw new Error('Gagal mengambil data');
      }
    } catch (error) {
      if (error.response) {
        console.log('Gagal :', error.response.data.errors);
        setError(`Error: ${error.response.data.message || 'Terjadi kesalahan'}`);
      } else if (error.request) {
        setError('Tidak ada respon dari server.');
      } else {
        setError('Terjadi kesalahan. Silakan coba lagi.');
      }
    } finally {
      setIsLoading(false);  
    }
  };

  if (redirect) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <main id='ubah_password' className='ubah_password'>
      <PageTitle page="Ubah Password"/>
      <form className="row g-3" onSubmit={handleSubmit}>
        <div className="col-md-12">
          <label htmlFor="passwordLama" className="form-label">Password Lama</label>
          <input type="password" className="form-control" id="passwordLama" value={passwordLama} onChange={(e) => setPasswordLama(e.target.value)} />
        </div>
        <div className="col-md-12">
          <label htmlFor="passwordBaru" className="form-label">Password Baru</label>
          <input type="password" className="form-control" id="passwordBaru" value={passwordBaru} onChange={(e) => setPasswordBaru(e.target.value)} />
        </div>
        <div className="col-md-12">
          <label htmlFor="passwordConfirm" className="form-label">Konfirmasi Password</label>
          <input type="password" className="form-control" id="passwordConfirm" value={passwordConfirm} onChange={(e) => setPasswordConfirm(e.target.value)} />
        </div>
        <button type="submit" className="btn btn-primary" disabled={isLoading}>
            {isLoading ? 'Loading...' : 'Submit'}
        </button>
        {error && <p className="text-danger mt-3">{error}</p>}
      </form>
    </main>
  );
}

export default Ubah_Password;
