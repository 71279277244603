import React from 'react';
import './header.css';
import Logo from './Logo';
import NavBar from './NavBar';

function Header() {
    return (
        <header 
        id='header' 
        className='header fixed-top d-flex align-items-center'>
            <Logo />
            <NavBar />
        </header>
    )
}

export default Header;