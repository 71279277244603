import React, { useState, useEffect } from 'react';
import './dashboard.css';
import Card from './Card';
import axios from 'axios';
import GrafikKesehatan from './GrafikKesehatan';
import GrafikKehadiran from './GrafikKehadiran';
import PieChartReport from './PieChartReport';
import JadwalMendatang from './JadwalMendatang';

function Dashboard() {

  const getCurrentMonth = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Tambahkan padStart untuk memastikan dua digit
    return { year, month };
  };

  const [vitamin, setVitamin] = useState([]);
  const [peserta, setPeserta] = useState([]);
  const [petugas, setPetugas] = useState([]);
  const [filter, setFilter] = useState(getCurrentMonth());
  const token = localStorage.getItem('access_token');


  useEffect(() => {
    const fetchVitamin = async () => {
      try {
        const response = await axios.get('https://api.posbindubukitharapan.com/api/vitamin', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          params: { nama_vitamin : '' }, // Gunakan params untuk filter
        });

        if (response.status !== 200) {
          throw new Error('Gagal mengambil data');
        }

        setVitamin(response.data.data.total);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    fetchVitamin();
  }, []);

  useEffect(() => {
    const fetchPeserta = async () => {
      try {
        const response = await axios.get('https://api.posbindubukitharapan.com/api/pesertas', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
        });

        if (response.status !== 200) {
          throw new Error('Gagal mengambil data');
        }

        setPeserta(response.data.data.total);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    fetchPeserta();
  }, []);

  useEffect(() => {
    const fetchPetugas = async () => {
      try {
        const response = await axios.get('https://api.posbindubukitharapan.com/api/petugass', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
        });

        if (response.status !== 200) {
          throw new Error('Gagal mengambil data');
        }

        setPetugas(response.data.data.total);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    fetchPetugas();
  }, []);

  return (
    <section className="dashboard section">
      <div className='row'>
        <div className="col-lg-2">
          <div className="filter-section">
            <input
              type="month"
              id="filterDate"
              value={`${filter.year}-${filter.month}`}
              onChange={(e) => {
                const [year, month] = e.target.value.split('-');
                setFilter({ year, month });
              }}
              className="form-control"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8">
          <div className="row">
          <Card name='Peserta' length={peserta} icon='bi bi-person' />
          <Card name='Petugas' length={petugas} icon='bi bi-person-fill' />
          <Card name='Vitamin' length={vitamin} icon='bi bi-capsule' />
          
          <div className="col-12">
            <GrafikKesehatan filter={filter}/>
          </div>
          <div className="col-12">
            <GrafikKehadiran filter={filter}/>
          </div>
          </div>
        </div>
        <div className="col-lg-4">
          <JadwalMendatang />
          <PieChartReport filter={filter}/>
        </div>
      </div>
    </section>
  );
}

export default Dashboard;
